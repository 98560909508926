const reportScales = {
  Agreeableness: 1,
  Conscientiousness: 2,
  Drive: 3,
  Extraversion: 4,
  Openness: 5,
  Sensibility: 6,
  Derailment: 7,
  'Tactical Intelligence': 8,
  'Strategic Intelligence': 9,
  'Emotional Intelligence': 10,
}

export default reportScales
