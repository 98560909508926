import React, {ReactNode} from 'react'
import {Button, Modal, Spinner} from 'react-bootstrap'

export type ConfirmModalProps = {
  show: boolean
  processing?: boolean
  title?: string
  question?: ReactNode
  cancelButtonText?: string
  confirmButtonText?: string
  onClickCancel?: () => void
  onClickConfirm?: () => void
}

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  show = false,
  processing = false,
  title = 'Confirm Delete',
  question = 'Are you sure?',
  cancelButtonText = 'Cancel',
  confirmButtonText = 'Confirm',
  onClickConfirm = () => {},
  onClickCancel = () => {},
}) => {
  return (
    <Modal show={show} onHide={onClickCancel}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{question}</Modal.Body>
      <Modal.Footer>
        <Button
          variant='secondary'
          onClick={(e) => {
            e.stopPropagation()
            if (onClickCancel) onClickCancel()
          }}
        >
          {cancelButtonText}
        </Button>
        <Button
          variant='danger'
          onClick={(e) => {
            e.stopPropagation()
            if (onClickConfirm) onClickConfirm()
          }}
          disabled={processing}
        >
          {processing && <Spinner animation='border' size='sm' />}
          {confirmButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmModal
