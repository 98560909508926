import React, {createContext, useContext, useState} from 'react'
import {Button, Form, Modal} from 'react-bootstrap'
import Select from 'react-select'
import {ReportContentScoreTypes} from '../../constants/reportContentScoreTypes'
import CustomReportContentTypes from '../../constants/customReportContentTypes'
import {competencyValueTypes, scaleValueTypes} from '../../constants/reportContentValueTypes'
import RichTextEditor from '../RichTextEditor/RichTextEditor'
import {WithChildrenProps} from '../../../types'
import {CustomReportContentModel} from '../../models/CustomReportContentModel'
import CustomReportContentChart from './CustomReportContentChart'

type HandlePropsType = {
  onUpdate: (content: CustomReportContentModel) => void
  onCancel?: () => void
}

const competencyKeyOptions = competencyValueTypes.map((key) => ({
  value: key,
  label: key,
}))
const scaleKeyOptions = scaleValueTypes.map((key) => ({
  value: key,
  label: key,
}))
const allNumberValueOptions = [...competencyKeyOptions, ...scaleKeyOptions]
export interface CustomReportContentEditorContextModel {
  showEditModal: (
    content: CustomReportContentModel,
    onUpdate: (content: CustomReportContentModel) => void,
    onCancel?: () => void
  ) => void
  hideEditModal: () => void
}

const CustomReportContentEditorModalContext = createContext<CustomReportContentEditorContextModel>({
  showEditModal: (content, onUpdate, onCancel) => {},
  hideEditModal: () => {},
})

const CustomReportContentEditorProvider: React.FC<WithChildrenProps> = ({children}) => {
  const [show, setShow] = useState(false)
  const [reportContent, setReportContent] = useState<CustomReportContentModel | null>(null)
  const [handleProps, setHandleProps] = useState<HandlePropsType | null>(null)

  const updateData = (fieldsToUpdate: Partial<CustomReportContentModel>): void => {
    const updatedData = Object.assign(
      {...reportContent},
      fieldsToUpdate
    ) as CustomReportContentModel
    setReportContent(updatedData)
  }

  function hideEditModal() {
    setShow(false)
  }

  function showEditModal(
    content: CustomReportContentModel,
    onUpdate: (content: CustomReportContentModel) => void,
    onCancel?: () => void
  ) {
    setReportContent({...content})
    setHandleProps({
      onUpdate,
      onCancel,
    })
    setShow(true)
  }

  const value: CustomReportContentEditorContextModel = {
    showEditModal,
    hideEditModal,
  }

  function handleClickCancel() {
    if (handleProps?.onCancel) handleProps.onCancel()
    setShow(false)
  }

  function handleClickConfirm() {
    if (reportContent && handleProps?.onUpdate) handleProps.onUpdate(reportContent)
    setShow(false)
  }
  function renderReportContentForms(contentType: CustomReportContentTypes) {
    switch (contentType) {
      case CustomReportContentTypes.CHART:
        return (
          <div>
            <Form.Group className='mb-3' controlId='reportValueKey'>
              <Form.Label className='required'>Value</Form.Label>
              <Select
                isMulti
                closeMenuOnSelect={false}
                options={allNumberValueOptions}
                value={allNumberValueOptions.filter((item) =>
                  reportContent?.chartValueKeys?.includes(item.value)
                )}
                onChange={(value) => updateData({chartValueKeys: value.map((item) => item.value)})}
              />
            </Form.Group>
            <CustomReportContentChart reportContent={reportContent} />
          </div>
        )
      case CustomReportContentTypes.PROGRESS_BAR:
        return (
          <div>
            <Form.Group className='mb-3' controlId='reportValueKey'>
              <Form.Label className='required'>Value</Form.Label>
              <Select
                options={allNumberValueOptions}
                value={allNumberValueOptions.find((item) => item.value === reportContent?.valueKey)}
                onChange={(value) => updateData({valueKey: value?.value || ''})}
              />
            </Form.Group>
          </div>
        )
      default:
        return (
          <div>
            <Form.Group className='mb-4' controlId='reportHtml'>
              <Form.Label className='required'>Contents</Form.Label>
              <span className='ml-2'>
                (Please use <span className='text-danger fw-bolder'>Insert Score Symbols</span> menu
                of <span className='text-danger fw-bolder'>Toolbar</span> for reporting value)
              </span>
              <RichTextEditor
                height={400}
                value={reportContent?.html}
                onChange={(value: string) => updateData({html: value})}
              />
            </Form.Group>
          </div>
        )
    }
  }

  return (
    <CustomReportContentEditorModalContext.Provider value={value}>
      {children}
      <Modal show={show} onHide={() => handleClickCancel()} size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>Content Editor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {renderReportContentForms(reportContent?.contentType || CustomReportContentTypes.HTML)}

            <Form.Group className='mb-4' controlId='reportIsDynamicReport'>
              <Form.Check
                type='checkbox'
                label='Is Dynamic Report'
                checked={reportContent?.isDynamicReport}
                onChange={(e) => updateData({isDynamicReport: e.target.checked})}
              />
            </Form.Group>
            {reportContent?.isDynamicReport && (
              <>
                <div className='row'>
                  <div className='col-12 col-md-4'>
                    <Form.Group className='mb-4' controlId='reportAssessmentType'>
                      <Form.Label>Score Type</Form.Label>
                      <Form.Select
                        value={reportContent.assessmentType}
                        onChange={(e) =>
                          updateData({assessmentType: (e.target as HTMLSelectElement).value})
                        }
                      >
                        <option value=''>Select One</option>
                        {Object.values(ReportContentScoreTypes).map((scoreType) => (
                          <option value={scoreType} key={scoreType}>
                            {scoreType}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </div>
                </div>
                {!!reportContent.assessmentType && (
                  <div className='row'>
                    <div className='col-12 col-md-6'>
                      <Form.Group className='mb-4' controlId='reportScoreKey'>
                        <Form.Label>Related Score Key</Form.Label>
                        <Select
                          options={
                            reportContent.assessmentType === ReportContentScoreTypes.PPA
                              ? [...scaleKeyOptions, ...competencyKeyOptions]
                              : competencyKeyOptions
                          }
                          value={[...scaleKeyOptions, ...competencyKeyOptions].find(
                            (item) => item.value === reportContent.scoreKey
                          )}
                          onChange={(newValue) => updateData({scoreKey: newValue?.value || ''})}
                        />
                      </Form.Group>
                    </div>
                    <div className='col-12 col-md-6'>
                      <div className='row mt-5'>
                        <Form.Group className='col' controlId='reportIsHighestScoreSection'>
                          <Form.Check
                            type='radio'
                            label='Highest Score Section'
                            name='isHighestScoreSection'
                            checked={reportContent?.isHighestScoreSection}
                            onClick={(e) => updateData({isHighestScoreSection: true})}
                          />
                        </Form.Group>

                        <Form.Group className='col' controlId='reportIsLowestScoreSection'>
                          <Form.Check
                            type='radio'
                            label='Lowest Score Section'
                            name='isHighestScoreSection'
                            checked={!reportContent?.isHighestScoreSection}
                            onClick={(e) => updateData({isHighestScoreSection: false})}
                          />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => handleClickCancel()}>
            Cancel
          </Button>
          <Button variant='primary' onClick={() => handleClickConfirm()}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
    </CustomReportContentEditorModalContext.Provider>
  )
}

export {
  CustomReportContentEditorModalContext as ReportContentEditorModalContext,
  CustomReportContentEditorProvider as ReportContentEditorProvider,
}

export function useCustomReportContentEditor() {
  return useContext(CustomReportContentEditorModalContext)
}
