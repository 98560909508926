import {FC} from 'react'
import {Link} from 'react-router-dom'
import routerItems from '../../../app/routing/routerItems'
import {useLayout} from '../core'

const PointLeaderFooter: FC = () => {
  const {classes} = useLayout()
  return (
    <div
      className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
    >
      <div className='text-dark order-2 order-md-1'>
        <span className='text-muted fw-bold me-2'>{new Date().getFullYear()} &copy;</span>
        <a href='#' className='text-gray-800 text-hover-primary'>
          PointLeader
        </a>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-bold order-1'>
        <li className='menu-item'>
          <Link to={routerItems.CONTACT_US} className='menu-link px-3'>
            Contact Us
          </Link>
        </li>
        <li className='menu-item'>
          <Link to={routerItems.FAQ} className='menu-link px-3'>
            FAQs
          </Link>
        </li>
        <li className='menu-item'>
          <Link to={routerItems.ABOUT} className='menu-link px-3'>
            About
          </Link>
        </li>
        <li className='menu-item'>
          <Link to={routerItems.TERMS} className='menu-link px-3'>
            Terms
          </Link>
        </li>
        <li className='menu-item'>
          <Link to={routerItems.PRIVACY} className='menu-link pl-3 pr-0'>
            Privacy
          </Link>
        </li>
      </ul>
    </div>
  )
}

export default PointLeaderFooter
