import React, {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import {MasterInit} from '../../_metronic/layout/MasterInit'
import routerItems from './routerItems'
import Layout from '../../_metronic/layout/Layout'
import {FallbackView} from '../../_metronic/partials'
import PrivateRoutes from './PrivateRoutes'
import {UserModel} from '../models/UserModel'
import {ClientModel} from '../models/ClientModel'
import routes from './routes'
import {ErrorsPage} from './pages'
import {isHrManager} from '../utils/authHelper'

const AppRoutes: FC = () => {
  const isAuthorized = useSelector<RootState>((state) => state.auth.user, shallowEqual)
  const currentUser = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as
    | UserModel
    | undefined
  const clients = useSelector<RootState>(
    (state) => state.baseData.clients,
    shallowEqual
  ) as ClientModel[]
  const firstPageUrl = isHrManager(currentUser?.roles)
    ? routerItems.HR_ADMIN_COMPARE_OVERALL_SCORE
    : clients.find((client) => client._id === currentUser?.clientId)?.firstPageUrl ||
      routerItems.MY_ASSESSMENTS

  return (
    <Layout>
      <Suspense fallback={<FallbackView />}>
        <Routes>
          <Route
            path={routerItems.HOME}
            element={<Navigate to={isAuthorized ? firstPageUrl : routerItems.AUTH_LOGIN} replace />}
          />
          {routes.map((route, id) => (
            <Route
              key={id}
              path={
                route.hasChild
                  ? route.path + (route.path.endsWith('/') ? '' : '/') + '*'
                  : route.path
              }
              element={
                <Suspense fallback={<FallbackView />}>
                  <PrivateRoutes route={route} />
                </Suspense>
              }
            />
          ))}
          <Route path='/index.html' element={<Navigate to={routerItems.HOME} replace />} />
          <Route path={'*'} element={<ErrorsPage />} />
        </Routes>
      </Suspense>
      <MasterInit />
    </Layout>
  )
}

export default AppRoutes
