import UserRole, {Permission} from '../constants/userRoles'
import {UserModel} from '../models/UserModel'

export function isApplicant(roles: Array<string> = []) {
  return roles.includes(UserRole.APPLICANT)
}
export function isEmployee(roles: Array<string> = []) {
  return roles.includes(UserRole.EMPLOYEE)
}
export function isMentor(roles: Array<string> = []) {
  return roles.includes(UserRole.MENTOR)
}
export function isTeamLeader(roles: Array<string> = []) {
  return roles.includes(UserRole.TEAM_LEADER)
}
export function isHrManager(roles: Array<string> = []) {
  return roles.includes(UserRole.HR_ADMIN)
}
export function isManager(roles: Array<string> = []) {
  return roles.includes(UserRole.MANAGER)
}
export function isSuperAdmin(roles: Array<string> = []) {
  return roles.includes(UserRole.SUPER_ADMIN)
}

export function hasManagerRole(roles: Array<string> = []) {
  return roles.includes(UserRole.MANAGER) || roles.includes(UserRole.SUPER_ADMIN)
}

export function hasHrManagerRole(roles: Array<string> = []) {
  return (
    roles.includes(UserRole.HR_ADMIN) ||
    roles.includes(UserRole.MANAGER) ||
    roles.includes(UserRole.SUPER_ADMIN)
  )
}

export function hasTeamLeaderRole(roles: Array<string> = []) {
  return (
    roles.includes(UserRole.TEAM_LEADER) ||
    // roles.includes(UserRole.HR_ADMIN) ||
    // roles.includes(UserRole.MANAGER) ||
    roles.includes(UserRole.SUPER_ADMIN)
  )
}

export function hasEmployeeRole(roles: Array<string> = []) {
  return (
    roles.includes(UserRole.EMPLOYEE) ||
    roles.includes(UserRole.HR_ADMIN) ||
    roles.includes(UserRole.MANAGER) ||
    roles.includes(UserRole.SUPER_ADMIN)
  )
}

export function hasApplicantRole(roles: Array<string> = []) {
  return roles.includes(UserRole.APPLICANT) || roles.includes(UserRole.SUPER_ADMIN)
}

export const getUserPermission = (user?: UserModel) => {
  if (!user || !user.roles.length) return Permission.Public
  const roles = user.roles
  if (isSuperAdmin(roles)) return Permission.SuperAdmin
  else if (hasManagerRole(roles)) return Permission.Manager
  else if (hasHrManagerRole(roles)) return Permission.Manager
  else if (hasTeamLeaderRole(roles)) return Permission.Manager
  else if (hasEmployeeRole(roles)) return Permission.Manager
  else if (hasApplicantRole(roles)) return Permission.Manager
  return Permission.Authorized
}

const permissionHelper = {
  isApplicant,
  isEmployee,
  isMentor,
  isTeamLeader,
  isHrManager,
  isManager,
  isSuperAdmin,

  hasManagerRole,
  hasHrManagerRole,
  hasTeamLeaderRole,
  hasEmployeeRole,
  hasApplicantRole,
  getUserPermission,
}

export default permissionHelper
