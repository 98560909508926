import {useEffect, useState} from 'react'
import {
  Button,
  FormControl,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from 'react-bootstrap'

type RichTextEditorSourceCodeModalProps = {
  html?: string
  show: boolean
  onCancel: () => void
  onSubmit: (updatedHtml: string) => void
}
const RichTextEditorSourceCodeModal: React.FC<RichTextEditorSourceCodeModalProps> = ({
  show,
  html = '',
  onCancel,
  onSubmit,
}) => {
  const [source, setSource] = useState('')
  useEffect(() => {
    setSource(html)
  }, [html])
  function handleCancel() {
    onCancel()
    setSource('')
  }
  function handleSubmit() {
    onSubmit(source)
    setSource('')
  }
  return (
    <Modal scrollable size='xl' show={show}>
      <ModalHeader onHide={handleCancel}>
        <ModalTitle>Source Editor</ModalTitle>
      </ModalHeader>
      <ModalBody>
        <FormControl
          as='textarea'
          value={source}
          onChange={(e) => setSource(e.target.value || '')}
          rows={20}
        />
      </ModalBody>
      <ModalFooter>
        <Button variant='secondary' onClick={handleCancel}>
          Cancel
        </Button>
        <Button variant='primary' onClick={handleSubmit}>
          Update
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RichTextEditorSourceCodeModal
