import {AsideMenuItem} from '../AsideMenuItem'
import routerItems from '../../../../../app/routing/routerItems'
import {useIntl} from 'react-intl'
import i18nKeys from '../../../../i18n/i18nKeys'

export default function HrManagerAsideMenu() {
  const {formatMessage} = useIntl()
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {formatMessage({id: i18nKeys.HR_ADMIN_PAGES})}
          </span>
        </div>
      </div>

      <AsideMenuItem
        icon='/media/icons/duotune/communication/com005.svg'
        to={routerItems.HR_ADMIN_COMPANY_EMPLOYEES}
        title={formatMessage({id: i18nKeys.COMPANY_EMPLOYEES})}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen016.svg'
        to={routerItems.HR_ADMIN_INVITE_APPLICANTS}
        title={'Invite Applicants'}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/graphs/gra001.svg'
        to={routerItems.HR_ADMIN_COMPARE_CANDIDATES}
        title={formatMessage({id: i18nKeys.COMPARE_CANDIDATES})}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/graphs/gra002.svg'
        to={routerItems.HR_ADMIN_COMPARE_GROUP}
        title={formatMessage({id: i18nKeys.COMPARE_GROUP})}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/graphs/gra010.svg'
        to={routerItems.HR_ADMIN_COMPARE_OVERALL_SCORE}
        title={'Compare Overall Score'}
      />
    </>
  )
}
