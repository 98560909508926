import {Action} from '@reduxjs/toolkit'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import languages from '../../../constants/languages'
import {UserModel} from '../../../models/UserModel'
import {getUserByToken} from './AuthCRUD'

export interface ActionWithPayload<T> extends Action {
  payload?: T
}

export const actionTypes = {
  Login: '[Login] Action',
  Logout: '[Logout] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
  SetUser: '[Set User] Action',
  ChangeReportLanguage: 'Change Report Language',
}

export interface IAuthState {
  user?: UserModel
  accessToken?: string
  reportLanguage?: string
}

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  reportLanguage: languages.ENGLISH,
}

export const userReducer = persistReducer(
  {storage, key: 'point-leader', whitelist: ['user', 'accessToken', 'reportLanguage']},
  (state: IAuthState = initialAuthState, action: ActionWithPayload<IAuthState>) => {
    switch (action.type) {
      case actionTypes.ChangeReportLanguage: {
        const reportLanguage = action.payload?.reportLanguage || languages.ENGLISH
        return {...state, reportLanguage}
      }
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken
        return {...state, accessToken}
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken
        return {accessToken, user: undefined}
      }

      case actionTypes.Logout: {
        return initialAuthState
      }

      case actionTypes.UserRequested: {
        return {...state, user: undefined}
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user
        return {...state, user, reportLanguage: user?.language || languages.ENGLISH}
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user
        return {...state, user, reportLanguage: user?.language || languages.ENGLISH}
      }

      default:
        return state
    }
  }
)

export const userActions = {
  login: (accessToken: string) => ({type: actionTypes.Login, payload: {accessToken}}),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: {accessToken},
  }),
  logout: () => ({type: actionTypes.Logout}),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: UserModel) => ({type: actionTypes.UserLoaded, payload: {user}}),
  setUser: (user: UserModel) => ({type: actionTypes.SetUser, payload: {user}}),
  changeReportLanguage: (reportLanguage: string) => ({
    type: actionTypes.ChangeReportLanguage,
    payload: {reportLanguage},
  }),
}

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(userActions.requestUser())
  })

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(userActions.requestUser())
  })

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const {data: user} = yield getUserByToken()
    yield put(userActions.fulfillUser(user))
  })
}
