import {all} from 'redux-saga/effects'
import {combineReducers} from 'redux'

import * as auth from '../../app/modules/auth'
import * as baseData from './BaseDataRedux'

export const rootReducer = combineReducers({
  auth: auth.userReducer,
  baseData: baseData.baseDataReducer,
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga(), baseData.sagaBaseData()])
}
