const GET_USER_FULL_INFO = `auth/get-user`
const LOGIN_URL = `auth/login`
const EMAIL_CHECK = `auth/email-check`
const REGISTER_URL = `auth/register`
const FORGOT_PASSWORD = `auth/forgot-password`
const EMAIL_CONFIRMATION = `auth/email-confirm/`
const RESET_PASSWORD = `auth/reset-password/`
const RESEND_CONFIRM_EMAIL = `auth/resend-confirm-email/`

const ALL_FAQ = 'all-faq/'
const FAQ = 'faq/'
const SEND_EMAIL_TO_US = 'send-email-to-us'

const PUBLIC_REPORT = (
  clientCode = ':clientCode',
  employeeId = ':employeeId',
  assessmentId = ':assessmentId'
) => `public/share-report/${clientCode}/${employeeId}/${assessmentId}`

const SUPER_ADMIN = 'super-admin/'
const SUPER_ADMIN_CHANGE_USER_PASSWORD = SUPER_ADMIN + 'change-user-password'
const SUPER_ADMIN_CHANGE_USER_EMAIL = SUPER_ADMIN + 'change-user-email'
const SUPER_ADMIN_TOGGLE_USER_CONFIRMED = SUPER_ADMIN + 'toggle-user-confirmed'
const SUPER_ADMIN_TOGGLE_USER_BLOCKED = SUPER_ADMIN + 'toggle-user-blocked'
const SUPER_ADMIN_TOGGLE_USER_DEACTIVATE = SUPER_ADMIN + 'toggle-user-deactivate'
const SUPER_ADMIN_SIGNIN_AS_ANONYMOUS = SUPER_ADMIN + 'signin-as-anonymous'
const SUPER_ADMIN_SWITCH_TO_ORIGINAL_USER = SUPER_ADMIN + 'switch-to-original-user'

const INVITEES = 'invitees/'
const INVITEE = (id: string) => INVITEES + id
const INVITE_APPLICANTS = INVITEES + 'bulk'

// super admin api routes
const USERS = 'users/'
const CLIENT_USERS = 'client-users/'
const CLIENT_EMPLOYEES = 'client-employees/'
const CLIENT_USER_ASSESSMENTS = 'client-user-assessments/'
const CLIENT_USER_ASSESSMENTS_OLD = 'client-user-assessments-old/'
const DEFAULT_COMPETENCY_CUTS = 'default-competency-cuts'
const JOB_COMPETENCY_CUTS = 'job-competency-cuts/'
const CLIENT_SITES_JOB_COMPETENCY_CUTS = 'client-site-job-competency-cuts/'
const CLIENT_SITE_TOP_JOB_COMPETENCIES = 'client-site-job-top-competencies/'

const ASSIGN_CLIENT_SITE_TO_USER = (userId: string) => `${USERS}${userId}/assign-client-site`

const CLIENT_TEAM_LEADERS = 'client-team-leaders/'

// manager api routes
const JOBS = 'jobs/'
const JOBS_ALL = 'jobs-all/'
const CLIENTS = 'clients/'
const CLIENT_HR_ADMINS = (clientId: string) => `${CLIENTS}${clientId}/hr-admins`
const CLIENTS_ALL = 'clients-all/'
const CLIENT_SITES = 'client-sites/'
const QUESTIONS = 'questions/'
const REPORT_TEXTS = 'report-texts/'
const REPORT_TEXTS_ALL = 'report-texts-all/'

const AVAILABLE_EES = 'available-ees/'
const EMPLOYEE_ALL_ASSESSMENT_RESULTS = 'employee-all-assessment-results/'
const ASSIGNED_EMPLOYEE_REPORTS = 'assigned-employee-reports/'
const EE_LATEST_ASSESSMENT_RESULT = 'ee-latest-assessment-results/'
const EE_ASSESSMENT_SCORES = 'ee-assessment-scores/'
const EE_ASSESSMENT_RESULTS_BY_IDS = 'ee-assessment-results-by-ids/'
const EE_ASSESSMENT_RESULTS = 'ee-assessment-results/'
const EE_ASSESSMENTS_RESULT_SAVE_RESULT = EE_ASSESSMENT_RESULTS + 'save-result/'
const MY_ASSESSMENT_RESULTS = 'my-assessment-results/'
const EE_ASSESSMENT_RESULTS_OVERVIEW = EE_ASSESSMENT_RESULTS + ':id/'
const EE_ASSESSMENT_RESULTS_OVERVIEW_SCORE = EE_ASSESSMENT_RESULTS_OVERVIEW + 'score/'

const CLIENT_ASSESSMENTS = 'client-assessments/'
const COMPANY_ASSESSMENTS = 'company-assessments/'
const EMPLOYEE_ASSESSMENTS = 'employee-assessments/'
const EMPLOYEE_REPORTS = 'user-reports/'

const ASSIGN_ASSESSMENTS = 'assign-assessments/'
const ASSIGN_ASSESSMENTS_EMPLOYEES = ASSIGN_ASSESSMENTS + 'employees/'
const ASSIGN_ASSESSMENTS_ALL_ASSESSMENTS = ASSIGN_ASSESSMENTS + 'assessments/'
const ASSIGN_ASSESSMENTS_CLIENT_ASSESSMENTS = ASSIGN_ASSESSMENTS + 'client-assessments/'

const COMPANY_NOTIFICATIONS = 'company-notifications/'
const COMPANY_TRENDS = 'company-trends/'
const COMPANY_NEWS = 'company-news/'
const COMPANY_REPORTS = 'company-reports/'

const CUSTOM_REPORTS = 'custom-reports/'
const CUSTOM_REPORT_PAGES = 'custom-report-pages/'
const CUSTOM_REPORT_FULL_INFO = 'custom-report-full-info/'

const CUSTOM_REPORT_CLIENT_MAPS = 'custom-report-client-mapping/'
const CUSTOM_REPORT_CLIENT_MAPS_BY_REPORT = CUSTOM_REPORT_CLIENT_MAPS + 'by-report/'
const CUSTOM_REPORT_CLIENT_MAPS_BY_CLIENT = CUSTOM_REPORT_CLIENT_MAPS + 'by-client/'

const TODO_LIST = 'todo-list/'
const MY_SCHEDULES = 'schedules/me'

const HR_ADMIN_ASSESSMENTS = 'hr-admin-assessments/'
const HR_ADMIN_ASSIGN_ASSESSMENTS = 'hr-admin-assign-assessments/'
const COMPANY_CUSTOM_REPORT_ASSIGN = 'company-custom-report-assign/'
const COMPANY_CUSTOM_REPORTS = 'company-custom-reports/'

const INTERNAL_REPORT_ASSIGN = 'internal-report-assign/'
const INTERNAL_REPORT_ASSIGN_SETTING = (id = ':id') => `/internal-report-assign-setting/${id}`

const ASSESSMENTS = 'assessments/'
const ASSESSMENT_GROUPS = 'assessment-groups/'
const ASSESSMENT_FORMS = 'assessment-forms/'
const ASSESSMENT_FORMS_BY_ASSESSMENT = 'assessment-forms-by-assessment/'
const ASSESSMENT_FORM_SECTIONS = 'assessment-form-sections/'
const SECTION_INSTRUCTIONS = 'section-instructions/'

const MODULES = 'modules/'
const MODULES_ALL = 'all-modules/'
const APPLICATIONS_ALL = 'all-applications/'
const APPLICATIONS = 'applications/'
const APPLICATION_WORKFLOWS = 'app-workflows/'
const APPLICATION_WORKFLOWS_BY_APP = 'app-workflows-by-app/'

const THETA_PERCENTILES = 'theta-percentiles/'
const THETA_PERCENTILES_BY_CODE = 'theta-percentiles-by-code/'

const PARENT_SCALES = 'parent-scales/'
const SUBSCALES = 'subscales/'
const QUESTION_WORKFLOWS = 'question-workflows/'
const QUESTION_SCORES = 'question-scores/'

const DASHBOARD_APPLICANT = 'dashboard/applicant'
const DASHBOARD_EMPLOYEE = 'dashboard/employee'
const DASHBOARD_TEAM_LEADER = 'dashboard/team-leader'
const DASHBOARD_HR_MANAGER = 'dashboard/hr-manager'
const DASHBOARD_MANAGER = 'dashboard/manager'
const DASHBOARD_ADMIN = 'dashboard/admin'

const EVENT_LOGS = 'event-logs/'
const RECENT_EVENT_LOGS = 'recent-event-logs/'
const USER_EVENT_LOGS = 'user-event-logs/'
const USER_RECENT_EVENT_LOGS = 'user-event-logs/recent'
const AUDIT_LOG_SETTINGS = 'audit-log-settings/'

const RECENT_NOTIFICATIONS = 'recent-company-notifications/'

const EXTERNAL_EE_ASSESSMENT_RESULTS = 'external-ee-assessment-results'

const COMPARE_CANDIDATES = 'compare-candidates/'
const COMPARE_CANDIDATES_BASE_DATA = COMPARE_CANDIDATES + 'base-data'
const COMPARE_CANDIDATES_EE_ASSESSMENT_RESULTS = COMPARE_CANDIDATES + 'ee-assessment-results'
const COMPARE_CANDIDATES_EES_ASSESSMENT_RESULTS = COMPARE_CANDIDATES + 'ees-assessment-results'

const ASSESSMENT_CLIENT_MAPS = 'assessment-client-mapping/'
const ASSESSMENT_CLIENT_MAPS_BY_ASSESSMENT = ASSESSMENT_CLIENT_MAPS + 'by-assessment/'
const ASSESSMENT_CLIENT_MAPS_BY_CLIENT = ASSESSMENT_CLIENT_MAPS + 'by-client/'

const TEAMS = 'teams/'
const TEAM_MEMBERS = 'team-members/'
const TEAMS_BY_TEAM_LEADER = 'teams-by-team-leader/'
const TEAMS_BY_COMPANY = 'teams-by-company/'
const ASSIGN_TEAM_MEMBERS = 'assign-team-members/'
const USER_FEEDBACK = `feedback-user/`
const FEEDBACK = `feedback/`

const GOAL_SETTINGS = 'goal-settings/'
const EMPLOYEE_GOAL_SETTINGS = 'employee-goal-settings/'

const CALC_TARGET_OVERALL_SCORES = (userId: string, jobId: string) =>
  `/calc-target-overall-score/${userId}/${jobId}`

const ACCOUNT = '/account/'
const ACCOUNT_SECONDARY_EMAILS = ACCOUNT + 'secondary-emails'
const ACCOUNT_SET_PRIMARY_EMAIL = ACCOUNT + 'set-primary-email'
const ACCOUNT_CHANGE_PASSWORD = ACCOUNT + 'change-password'

const CLIENT_API_KEY = (clientId: string) => `/client-api-key/${clientId}`

const TWO_FACTOR = '/two-factor'
const TWO_FACTOR_AUTHENTICATOR = TWO_FACTOR + '/authenticator'
const TWO_FACTOR_AUTHENTICATOR_SETUP = TWO_FACTOR_AUTHENTICATOR + '/setup'
const TWO_FACTOR_AUTHENTICATOR_VERIFY = TWO_FACTOR_AUTHENTICATOR + '/verify'
const TWO_FACTOR_AUTHENTICATOR_DISABLE = TWO_FACTOR_AUTHENTICATOR + '/disable'

const TWO_FACTOR_SMS = TWO_FACTOR + '/sms'
const TWO_FACTOR_SMS_SETUP = TWO_FACTOR_SMS + '/setup'
const TWO_FACTOR_SMS_SEND_AUTH_CODE = TWO_FACTOR_SMS + '/send-auth-code'
const TWO_FACTOR_SMS_VERIFY = TWO_FACTOR_SMS + '/verify'
const TWO_FACTOR_SMS_DISABLE = TWO_FACTOR_SMS + '/disable'

const apiRouterItems = {
  PUBLIC_REPORT,

  ALL_FAQ,
  FAQ,
  SEND_EMAIL_TO_US,

  DASHBOARD_APPLICANT,
  DASHBOARD_EMPLOYEE,
  DASHBOARD_TEAM_LEADER,
  DASHBOARD_HR_MANAGER,
  DASHBOARD_MANAGER,
  DASHBOARD_ADMIN,

  TODO_LIST,

  COMPANY_NEWS,
  COMPANY_REPORTS,
  COMPANY_NOTIFICATIONS,
  COMPANY_TRENDS,

  GET_USER_FULL_INFO,
  USER_FEEDBACK,
  FEEDBACK,
  LOGIN_URL,
  EMAIL_CHECK,
  REGISTER_URL,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  EMAIL_CONFIRMATION,
  RESEND_CONFIRM_EMAIL,

  DEFAULT_COMPETENCY_CUTS,
  JOB_COMPETENCY_CUTS,
  CLIENT_SITES_JOB_COMPETENCY_CUTS,
  CLIENT_SITE_TOP_JOB_COMPETENCIES,
  THETA_PERCENTILES,
  THETA_PERCENTILES_BY_CODE,

  JOBS,
  JOBS_ALL,
  CLIENTS,
  CLIENTS_ALL,
  CLIENT_SITES,
  CLIENT_HR_ADMINS,
  QUESTIONS,

  REPORT_TEXTS,
  REPORT_TEXTS_ALL,

  EE_LATEST_ASSESSMENT_RESULT,
  MY_ASSESSMENT_RESULTS,
  AVAILABLE_EES,
  EMPLOYEE_ALL_ASSESSMENT_RESULTS,
  EE_ASSESSMENT_SCORES,
  EE_ASSESSMENT_RESULTS_BY_IDS,
  EE_ASSESSMENT_RESULTS,
  EE_ASSESSMENT_RESULTS_OVERVIEW,
  EE_ASSESSMENT_RESULTS_OVERVIEW_SCORE,
  EE_ASSESSMENTS_RESULT_SAVE_RESULT,

  CLIENT_ASSESSMENTS,
  COMPANY_ASSESSMENTS,
  EMPLOYEE_ASSESSMENTS,
  EMPLOYEE_REPORTS,

  ASSIGN_ASSESSMENTS,
  ASSIGN_ASSESSMENTS_EMPLOYEES,
  ASSIGN_ASSESSMENTS_ALL_ASSESSMENTS,
  ASSIGN_ASSESSMENTS_CLIENT_ASSESSMENTS,

  HR_ADMIN_ASSESSMENTS,
  HR_ADMIN_ASSIGN_ASSESSMENTS,

  INTERNAL_REPORT_ASSIGN,
  INTERNAL_REPORT_ASSIGN_SETTING,

  ASSESSMENTS,
  ASSESSMENT_GROUPS,
  ASSESSMENT_FORMS,
  ASSESSMENT_FORMS_BY_ASSESSMENT,
  ASSESSMENT_FORM_SECTIONS,
  SECTION_INSTRUCTIONS,

  MODULES,
  MODULES_ALL,

  APPLICATIONS,
  APPLICATIONS_ALL,

  APPLICATION_WORKFLOWS,
  APPLICATION_WORKFLOWS_BY_APP,

  PARENT_SCALES,
  SUBSCALES,
  QUESTION_WORKFLOWS,
  QUESTION_SCORES,

  USERS,
  CLIENT_USERS,
  CLIENT_EMPLOYEES,
  CLIENT_TEAM_LEADERS,
  CLIENT_USER_ASSESSMENTS,
  CLIENT_USER_ASSESSMENTS_OLD,

  EVENT_LOGS,
  RECENT_EVENT_LOGS,
  USER_EVENT_LOGS,
  USER_RECENT_EVENT_LOGS,

  AUDIT_LOG_SETTINGS,

  RECENT_NOTIFICATIONS,
  EXTERNAL_EE_ASSESSMENT_RESULTS,

  COMPARE_CANDIDATES_BASE_DATA,
  COMPARE_CANDIDATES_EE_ASSESSMENT_RESULTS,
  COMPARE_CANDIDATES_EES_ASSESSMENT_RESULTS,

  SUPER_ADMIN_CHANGE_USER_PASSWORD,
  SUPER_ADMIN_CHANGE_USER_EMAIL,
  SUPER_ADMIN_TOGGLE_USER_CONFIRMED,
  SUPER_ADMIN_TOGGLE_USER_BLOCKED,
  SUPER_ADMIN_TOGGLE_USER_DEACTIVATE,
  SUPER_ADMIN_SIGNIN_AS_ANONYMOUS,
  SUPER_ADMIN_SWITCH_TO_ORIGINAL_USER,

  ASSESSMENT_CLIENT_MAPS,
  ASSESSMENT_CLIENT_MAPS_BY_ASSESSMENT,
  ASSESSMENT_CLIENT_MAPS_BY_CLIENT,

  TEAMS,
  TEAMS_BY_TEAM_LEADER,
  TEAMS_BY_COMPANY,
  TEAM_MEMBERS,
  ASSIGN_TEAM_MEMBERS,

  GOAL_SETTINGS,
  EMPLOYEE_GOAL_SETTINGS,

  ASSIGNED_EMPLOYEE_REPORTS,
  MY_SCHEDULES,

  CALC_TARGET_OVERALL_SCORES,

  ACCOUNT_CHANGE_PASSWORD,
  ACCOUNT_SECONDARY_EMAILS,
  ACCOUNT_SET_PRIMARY_EMAIL,
  CLIENT_API_KEY,

  TWO_FACTOR,
  TWO_FACTOR_AUTHENTICATOR,
  TWO_FACTOR_AUTHENTICATOR_SETUP,
  TWO_FACTOR_AUTHENTICATOR_VERIFY,
  TWO_FACTOR_AUTHENTICATOR_DISABLE,
  TWO_FACTOR_SMS,
  TWO_FACTOR_SMS_SETUP,
  TWO_FACTOR_SMS_SEND_AUTH_CODE,
  TWO_FACTOR_SMS_VERIFY,
  TWO_FACTOR_SMS_DISABLE,

  CUSTOM_REPORTS,
  CUSTOM_REPORT_PAGES,
  CUSTOM_REPORT_FULL_INFO,
  CUSTOM_REPORT_CLIENT_MAPS,
  CUSTOM_REPORT_CLIENT_MAPS_BY_CLIENT,
  CUSTOM_REPORT_CLIENT_MAPS_BY_REPORT,

  COMPANY_CUSTOM_REPORT_ASSIGN,
  COMPANY_CUSTOM_REPORTS,

  INVITEES,
  INVITEE,
  INVITE_APPLICANTS,

  ASSIGN_CLIENT_SITE_TO_USER,
}

export default apiRouterItems
