import {Alert, Button} from 'react-bootstrap'
import {Link} from 'react-router-dom'
import routerItems from '../../../../app/routing/routerItems'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import permissionHelper from '../../../../app/utils/authHelper'
import {useState} from 'react'

const TwoFactorRequireAlert: React.FC = () => {
  const [dismiss, setDismiss] = useState(false)
  const currentUser: UserModel = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel
  const roles: Array<string> = currentUser?.roles || []
  if (
    dismiss ||
    !permissionHelper.isSuperAdmin(roles) ||
    !!currentUser?.installedMFA?.[0]?.verified
  )
    return null
  return (
    <Alert variant='warning' className='mb-0 d-flex justify-content-between align-items-center'>
      <div>
        You have super admin role and you need to setup Two-Factor Authentication for security in{' '}
        <Link to={`${routerItems.ACCOUNT_SETTINGS}#auth-two-factor`}>your profile page</Link>.
      </div>
      <Button variant='warning' size='sm' className='ml-2' onClick={() => setDismiss(true)}>
        Dismiss
      </Button>
    </Alert>
  )
}

export default TwoFactorRequireAlert
