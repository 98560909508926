import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {toAbsoluteUrl} from '../../../helpers'

const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
  {
    lang: 'zh',
    name: 'Mandarin',
    flag: toAbsoluteUrl('/media/flags/china.svg'),
  },
  {
    lang: 'es',
    name: 'Spanish',
    flag: toAbsoluteUrl('/media/flags/spain.svg'),
  },
  {
    lang: 'ja',
    name: 'Japanese',
    flag: toAbsoluteUrl('/media/flags/japan.svg'),
  },
  {
    lang: 'de',
    name: 'German',
    flag: toAbsoluteUrl('/media/flags/germany.svg'),
  },
  {
    lang: 'fr',
    name: 'French',
    flag: toAbsoluteUrl('/media/flags/france.svg'),
  },
]

const Languages: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  const currentLanguage = languages.find((x) => x.lang === user.language)
  return (
    <div className='menu-title position-relative'>
      Language
      <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
        {currentLanguage?.name}{' '}
        <img className='w-15px h-15px rounded-1 ms-2' src={currentLanguage?.flag} alt='flag' />
      </span>
    </div>
  )
}

export {Languages}
