import React from 'react'

type LoadingProps = {
  className?: string
  description?: string
}

const Loading: React.FC<LoadingProps> = ({className, description = ''}) => {
  return (
    <span className={'indicator-progress ' + className} style={{display: 'block'}}>
      {description} <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
    </span>
  )
}

export default Loading
