import {AverageScoreLevels} from '../models/ClientSiteJobTopCompetencyModel'
import OverallJobFitScores from './overallJobFitScores'

const defaultFitScoreLabels = {
  high: 'High',
  moderateHigh: 'Moderate High',
  moderate: 'Moderate',
  low: 'Low',
}

export const defaultAverageScoreLevels: AverageScoreLevels = {
  weightedCounts: 5,
  weight: 1.2,
  high: 0.75,
  moderate: 0.5,
  low: 0.25,
}

export const defaultFilterOverallScores: Array<{
  overallJobFitScore: OverallJobFitScores
}> = [
  {
    overallJobFitScore: OverallJobFitScores.HIGH,
  },
  {
    overallJobFitScore: OverallJobFitScores.MODERATE_HIGH,
  },
  {
    overallJobFitScore: OverallJobFitScores.MODERATE,
  },
  {
    overallJobFitScore: OverallJobFitScores.LOW,
  },
]
export default defaultFitScoreLabels
