import {BlobServiceClient, ContainerClient} from '@azure/storage-blob'
import {v4 as uuidv4} from 'uuid'
const containerName = `pointerleader`
const sasToken = process.env.REACT_APP_STORAGE_SAS_TOKEN
const storageAccountName = process.env.REACT_APP_STORAGE_RESOURCE_NAME
// </snippet_package>

// <snippet_isStorageConfigured>
// Feature flag - disable storage feature to app if not configured
export const isStorageConfigured = () => {
  return !storageAccountName || !sasToken ? false : true
}
// </snippet_isStorageConfigured>

// <snippet_getBlobsInContainer>
// return list of blobs in container to display
const getBlobsInContainer = async (containerClient: ContainerClient, fileName: string) => {
  // get list of blobs in container
  // eslint-disable-next-line
  for await (const blob of containerClient.listBlobsFlat()) {
    // if image is public, just construct URL
    if (blob.name === fileName)
      return `https://${storageAccountName}.blob.core.windows.net/${containerName}/${blob.name}`
  }
  return ''
}

// <snippet_uploadFileToBlob>
const uploadFileToBlob = async (file: File | null): Promise<string> => {
  if (!file) return ''

  // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
  const blobService = new BlobServiceClient(
    `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
  )

  const fileName = uuidv4() + '-' + file.name.replace(/[\s/\\:*?"<>()]/g, '')
  try {
    // get Container - full public read access
    const containerClient: ContainerClient = blobService.getContainerClient(containerName)
    const isExisted = await containerClient.exists()
    if (!isExisted)
      await containerClient.createIfNotExists({
        access: 'container',
      })

    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(fileName)

    // set mimetype as determined from browser with file upload control
    const options = {blobHTTPHeaders: {blobContentType: file.type}}

    // upload file
    await blobClient.uploadData(file, options)
    // get list of blobs in container
    return getBlobsInContainer(containerClient, fileName)
  } catch (err) {
    throw new Error('Could not upload the file')
  }
}
// </snippet_uploadFileToBlob>

export default uploadFileToBlob
