import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import DefaultLayout from './DefaultLayout'
import {MasterLayout} from './MasterLayout'
import {WithChildrenProps} from '../../types'

const Layout: React.FC<WithChildrenProps> = ({children}) => {
  const isAuthorized = useSelector<RootState>(({auth}) => auth.user, shallowEqual)
  const LayoutComponent = isAuthorized ? MasterLayout : DefaultLayout
  return <LayoutComponent>{children}</LayoutComponent>
}

export default Layout
