import {AsideMenuItem} from '../AsideMenuItem'
import routerItems from '../../../../../app/routing/routerItems'
import {useSelector} from 'react-redux'
import {RootState} from '../../../../../setup'
import {useIntl} from 'react-intl'
import i18nKeys from '../../../../i18n/i18nKeys'
import useUser from '../../../../../app/hooks/useUser'

export default function FeaturesAsideMenu() {
  const {formatMessage} = useIntl()
  const {hasEmployeeRole: isEmployee, isSuperAdmin} = useUser()
  const allowShowAssessmentResultToEmployee = !!useSelector<RootState>(
    ({auth}) => auth.user?.clientSite?.allowShowAssessmentResultToEmployee
  )
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {formatMessage({id: i18nKeys.FEATURES})}
          </span>
        </div>
      </div>
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen020.svg'
        to={routerItems.MY_TODO_LIST}
        title={formatMessage({id: i18nKeys.MY_TODO_LIST})}
      />
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen005.svg'
        to={routerItems.MY_ASSESSMENTS}
        title={formatMessage({id: i18nKeys.MY_ASSESSMENTS})}
      />
      {allowShowAssessmentResultToEmployee && isEmployee && (
        <AsideMenuItem
          to={routerItems.MY_REPORTS}
          icon='/media/icons/duotune/art/art008.svg'
          title={formatMessage({id: i18nKeys.MY_REPORTS})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isSuperAdmin && (
        <>
          <AsideMenuItem
            to={routerItems.MY_ASSESSMENT_RESULTS}
            icon='/media/icons/duotune/art/art009.svg'
            title={formatMessage({id: i18nKeys.MY_ASSESSMENT_RESULTS})}
            fontIcon='bi-app-indicator'
          />
        </>
      )}
      <AsideMenuItem
        to={routerItems.MY_SCHEDULES}
        icon='/media/icons/duotune/art/art002.svg'
        title={formatMessage({id: i18nKeys.MY_SCHEDULES})}
        fontIcon='bi-app-indicator'
      />
    </>
  )
}
