import React, {createContext, useContext, useState} from 'react'
import ConfirmModal, {ConfirmModalProps} from '../../../app/components/ConfirmModal'
import {WithChildrenProps} from '../../../types'

type ConfirmModalParams = Omit<ConfirmModalProps, 'show' | 'processing'>

export interface ConfirmModalContextModel {
  showConfirmModal: (params: ConfirmModalParams) => void
  hideConfirmModal: () => void
  setProcessingStatus: (processing: boolean) => void
}

const ConfirmModalContext = createContext<ConfirmModalContextModel>({
  showConfirmModal: (params: ConfirmModalParams) => {},
  setProcessingStatus: (processing) => {},
  hideConfirmModal: () => {},
})

const ConfirmModalProvider: React.FC<WithChildrenProps> = ({children}) => {
  const [show, setShow] = useState(false)
  const [processingStatus, setProcessingStatus] = useState(false)
  const [modalProps, setModalProps] = useState<ConfirmModalParams>({
    title: undefined,
    question: undefined,
    cancelButtonText: undefined,
    onClickCancel: undefined,
    onClickConfirm: undefined,
  })

  function hideConfirmModal() {
    setShow(false)
    setProcessingStatus(false)
    setModalProps({
      title: undefined,
      question: undefined,
      cancelButtonText: undefined,
      onClickCancel: undefined,
      onClickConfirm: undefined,
    })
  }
  function showConfirmModal(params: ConfirmModalParams) {
    setShow(true)
    setProcessingStatus(false)
    setModalProps(params)
  }
  const value: ConfirmModalContextModel = {
    showConfirmModal,
    setProcessingStatus,
    hideConfirmModal,
  }

  function handleClickCancel() {
    setShow(false)
    if (modalProps.onClickCancel) modalProps.onClickCancel()
  }
  function handleClickConfirm() {
    if (modalProps.onClickConfirm) modalProps.onClickConfirm()
  }
  return (
    <ConfirmModalContext.Provider value={value}>
      {children}
      <ConfirmModal
        show={show}
        processing={processingStatus}
        {...modalProps}
        onClickCancel={handleClickCancel}
        onClickConfirm={handleClickConfirm}
      />
    </ConfirmModalContext.Provider>
  )
}

export {ConfirmModalContext, ConfirmModalProvider}

export function useConfirmModal() {
  return useContext(ConfirmModalContext)
}
