const scoringEngineTypes = {
  PPA: 'PPA', // PointLeader Potential Assessment
  PFA: 'PFA', // PointLeader Focus Assessment
  PBA: 'PBA', // PointLeader Behavioral Assessment
  PBI: 'PBI', // PointLeader Behavioral Interview Assessment
}
export const scoringEngineTypeLabels = {
  [scoringEngineTypes.PPA]: 'Potential Assessment',
  [scoringEngineTypes.PFA]: 'Focus Assessment',
  [scoringEngineTypes.PBA]: 'Behavior Assessment',
  [scoringEngineTypes.PBI]: 'Behavioral Interview Assessment',
}

export default scoringEngineTypes
