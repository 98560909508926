enum ReportContentValueTypes {
  // special values
  ParticipantName = 'ParticipantName',
  CompletedDate = 'CompletedDate',
  OverallScore = 'OverallScore',
  MaxPPAScaleScore = 'MaxPPAScaleScore',
  MinPPAScaleScore = 'MinPPAScaleScore',
  MaxPPAPotentialScore = 'MaxPPAPotentialScore',
  MinPPAPotentialScore = 'MinPPAPotentialScore',
  MaxPBAScore = 'MaxPBAScore',
  MinPBAScore = 'MinPBAScore',
  MaxPFAScore = 'MaxPFAScore',
  MinPFAScore = 'MinPFAScore',

  // scales
  Agreeableness = 'Agreeableness',
  Conscientiousness = 'Conscientiousness',
  Derailment = 'Derailment',
  Extraversion = 'Extraversion',
  Driver = 'Driver',
  Openness = 'Openness',
  Sensibility = 'Sensibility',
  StrategicIntelligence = 'StrategicIntelligence',
  EmotionalIntelligence = 'EmotionalIntelligence',
  TacticalIntelligence = 'TacticalIntelligence',

  // competencies
  AdaptabilityFlexibility = 'AdaptabilityFlexibility',
  BuildingPartnerships = 'BuildingPartnerships',
  BuildingTeams = 'BuildingTeams',
  Citizenship = 'Citizenship',
  ConcernForOthers = 'ConcernForOthers',
  ConflictResolution = 'ConflictResolution',
  ContinuousLearning = 'ContinuousLearning',
  Cooperation = 'Cooperation',
  CustomerOrientation = 'CustomerOrientation',
  DecisionMaking = 'DecisionMaking',
  Dependability = 'Dependability',
  DetailOrientation = 'DetailOrientation',
  EmployeeDevelopment = 'EmployeeDevelopment',
  FacilitatingChange = 'FacilitatingChange',
  FormalPresentation = 'FormalPresentation',
  Independence = 'Independence',
  IndustryKnowledge = 'IndustryKnowledge',
  Influence = 'Influence',
  Initiative = 'Initiative',
  Innovation = 'Innovation',
  InterpersonalSkills = 'InterpersonalSkills',
  Leadership = 'Leadership',
  MathSkills = 'MathSkills',
  Negotiation = 'Negotiation',
  OralCommunication = 'OralCommunication',
  Persistence = 'Persistence',
  PlanningOrganizing = 'PlanningOrganizing',
  ProblemSolving = 'ProblemSolving',
  Professionalism = 'Professionalism',
  ResultsOrientation = 'ResultsOrientation',
  RiskTaking = 'RiskTaking',
  SelfControl = 'SelfControl',
  SocialOrientation = 'SocialOrientation',
  StressTolerance = 'StressTolerance',
  Teamwork = 'Teamwork',
  TechnicalKnowledge = 'TechnicalKnowledge',
  TrainingTeaching = 'TrainingTeaching',
  Trustworthiness = 'Trustworthiness',
  WorkAttitude = 'WorkAttitude',
  WrittenCommunication = 'WrittenCommunication',
}

export const specialValueTypes = [
  ReportContentValueTypes.ParticipantName,
  ReportContentValueTypes.CompletedDate,
  ReportContentValueTypes.OverallScore,
  ReportContentValueTypes.MaxPPAScaleScore,
  ReportContentValueTypes.MinPPAScaleScore,
  ReportContentValueTypes.MaxPPAPotentialScore,
  ReportContentValueTypes.MinPPAPotentialScore,
  ReportContentValueTypes.MaxPBAScore,
  ReportContentValueTypes.MinPBAScore,
  ReportContentValueTypes.MaxPFAScore,
  ReportContentValueTypes.MinPFAScore,
]

export const scaleValueTypes = [
  ReportContentValueTypes.Agreeableness,
  ReportContentValueTypes.Conscientiousness,
  ReportContentValueTypes.Derailment,
  ReportContentValueTypes.Extraversion,
  ReportContentValueTypes.Driver,
  ReportContentValueTypes.Openness,
  ReportContentValueTypes.Sensibility,
  ReportContentValueTypes.StrategicIntelligence,
  ReportContentValueTypes.EmotionalIntelligence,
  ReportContentValueTypes.TacticalIntelligence,
]

export const competencyValueTypes = [
  ReportContentValueTypes.AdaptabilityFlexibility,
  ReportContentValueTypes.BuildingPartnerships,
  ReportContentValueTypes.BuildingTeams,
  ReportContentValueTypes.Citizenship,
  ReportContentValueTypes.ConcernForOthers,
  ReportContentValueTypes.ConflictResolution,
  ReportContentValueTypes.ContinuousLearning,
  ReportContentValueTypes.Cooperation,
  ReportContentValueTypes.CustomerOrientation,
  ReportContentValueTypes.DecisionMaking,
  ReportContentValueTypes.Dependability,
  ReportContentValueTypes.DetailOrientation,
  ReportContentValueTypes.EmployeeDevelopment,
  ReportContentValueTypes.FacilitatingChange,
  ReportContentValueTypes.FormalPresentation,
  ReportContentValueTypes.Independence,
  ReportContentValueTypes.IndustryKnowledge,
  ReportContentValueTypes.Influence,
  ReportContentValueTypes.Initiative,
  ReportContentValueTypes.Innovation,
  ReportContentValueTypes.InterpersonalSkills,
  ReportContentValueTypes.Leadership,
  ReportContentValueTypes.MathSkills,
  ReportContentValueTypes.Negotiation,
  ReportContentValueTypes.OralCommunication,
  ReportContentValueTypes.Persistence,
  ReportContentValueTypes.PlanningOrganizing,
  ReportContentValueTypes.ProblemSolving,
  ReportContentValueTypes.Professionalism,
  ReportContentValueTypes.ResultsOrientation,
  ReportContentValueTypes.RiskTaking,
  ReportContentValueTypes.SelfControl,
  ReportContentValueTypes.SocialOrientation,
  ReportContentValueTypes.StressTolerance,
  ReportContentValueTypes.Teamwork,
  ReportContentValueTypes.TechnicalKnowledge,
  ReportContentValueTypes.TrainingTeaching,
  ReportContentValueTypes.Trustworthiness,
  ReportContentValueTypes.WorkAttitude,
  ReportContentValueTypes.WrittenCommunication,
]
export default ReportContentValueTypes
