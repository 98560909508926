import {shallowEqual, useSelector} from 'react-redux'
import {Navigate, useLocation} from 'react-router-dom'
import {RootState} from '../../setup'
import routerItems from './routerItems'
import {RouteData} from './routes'
import permissionHelper from '../utils/authHelper'
import {UserModel} from '../models/UserModel'

function PrivateRoutes({route}: {route: RouteData}) {
  const user: UserModel | undefined = useSelector<RootState>(
    (state) => state.auth.user,
    shallowEqual
  ) as UserModel
  const userPermission = permissionHelper.getUserPermission(user)
  const {Component, permission: pagePermission} = route
  const {pathname} = useLocation()
  if (!pagePermission) return <Component />
  if (!Component || !user) return <Navigate to={`${routerItems.AUTH_LOGIN}?rtUrl=${pathname}`} />
  if (pagePermission > userPermission) return <Navigate to={routerItems.ERROR_NO_PERMISSION} />
  return <Component />
}

export default PrivateRoutes
