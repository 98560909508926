import {shallowEqual, useSelector} from 'react-redux'
import {UserModel} from '../models/UserModel'
import {RootState} from '../../setup'
import permissionHelper from '../utils/authHelper'
import {ClientModel} from '../models/ClientModel'

export default function useUser() {
  const user: UserModel | undefined = useSelector<RootState>(
    ({auth}) => auth.user,
    shallowEqual
  ) as UserModel | undefined
  const clients = useSelector<RootState>(
    ({baseData}) => baseData.clients,
    shallowEqual
  ) as ClientModel[]
  const hasApplicantRole = permissionHelper.hasApplicantRole(user?.roles)
  const hasEmployeeRole = permissionHelper.hasEmployeeRole(user?.roles)
  const hasTeamLeaderRole = permissionHelper.hasTeamLeaderRole(user?.roles)
  const hasHrManagerRole = permissionHelper.hasHrManagerRole(user?.roles)
  const hasManagerRole = permissionHelper.hasManagerRole(user?.roles)
  const isSuperAdmin = permissionHelper.isSuperAdmin(user?.roles)
  const assignedClientSites = (
    clients.find((client) => client._id === user?.clientId)?.clientSites || []
  ).filter((item) => !!user?.assignedClientSites?.find((it) => it._id === item._id))
  return {
    user,
    hasApplicantRole,
    hasEmployeeRole,
    hasTeamLeaderRole,
    hasHrManagerRole,
    hasManagerRole,
    isSuperAdmin,
    assignedClientSites,
  }
}
