import React from 'react'
import CustomReportContentTypes from '../../constants/customReportContentTypes'
import {Bar} from 'react-chartjs-2'
import './CustomReportContent.scss'
import stringHelper from '../../utils/stringHelper'
import {EEAssessmentResultModel} from '../../models/EEAssessmentResultModel'
import {Alert} from 'react-bootstrap'
import useResultScore from '../../hooks/useResultScore'
import {UserModel} from '../../models/UserModel'
import {CustomReportContentModel} from '../../models/CustomReportContentModel'

const barChartOptions = {
  indexAxis: 'y' as const,
  maintainAspectRatio: true,
  plugins: {
    title: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: false,
    },
    y: {
      stacked: false,
    },
  },
}
type ReportContentChartProps = {
  reportContent?: CustomReportContentModel | null
  employee?: UserModel
  eeAssessmentResults?: EEAssessmentResultModel[]
  topCompetencyIds?: string[]
}

const CustomReportContentChart: React.FC<ReportContentChartProps> = ({
  reportContent,
  employee,
  topCompetencyIds = [],
  eeAssessmentResults = [],
}) => {
  const {getReportScoreByScoreKey} = useResultScore()
  const valueKeys = reportContent?.chartValueKeys || []
  const data = {
    labels: valueKeys?.map((valueKey) => stringHelper.camelCaseToTitle(valueKey)),
    datasets: [
      {
        label: '',
        data: valueKeys.map((scoreKey) =>
          parseFloat(
            getReportScoreByScoreKey(scoreKey, employee, eeAssessmentResults, topCompetencyIds)
          )
        ),
        backgroundColor: '#273878',
      },
    ],
  }
  if (reportContent?.contentType !== CustomReportContentTypes.CHART) {
    return <div>This contents has invalid settings.</div>
  }
  return (
    <div className='report-content-chart-wrapper'>
      {valueKeys.length === 0 && (
        <Alert variant='warning'>Please select 1 value option at least.</Alert>
      )}
      <Bar options={barChartOptions} data={data} />
    </div>
  )
}

export default CustomReportContentChart
