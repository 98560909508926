import React, {useEffect, useState} from 'react'
import {Editor} from '@tinymce/tinymce-react'
import Loading from '../Loading'
import uploadFileToBlob from '../../utils/azureStorage'
import RichTextEditorSourceCodeModal from './RichTextEditorSourceCodeModal'
import {
  competencyValueTypes,
  scaleValueTypes,
  specialValueTypes,
} from '../../constants/reportContentValueTypes'

type RichTextEditorProp = {
  placeholder?: string
  initialValue?: string
  value?: string
  onChange?: Function
  height?: number
}

const scoreSymbolMenu = 'scoreSymbolMenu'
const RichTextEditor: React.FC<RichTextEditorProp> = ({
  placeholder,
  initialValue,
  onChange,
  value,
  height,
}) => {
  const [loading, setLoading] = useState(true)
  const [showSourceModal, setShowSourceModal] = useState(false)
  const handleEditorChange = (content: any) => {
    onChange && onChange(content)
  }
  useEffect(() => {
    document.addEventListener('focusin', (e: Event) => {
      if (
        (e?.target as Element)?.closest(
          '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root'
        ) !== null
      ) {
        e.stopImmediatePropagation()
      }
    })
  }, [])
  return (
    <div className='min-height-100px position-relative border rounded'>
      {loading && (
        <div className='p-10 text-center'>
          <Loading />
        </div>
      )}
      <Editor
        onInit={() => setLoading(false)}
        apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
        initialValue={initialValue}
        value={value}
        init={{
          setup: (editor) => {
            editor.ui.registry.addButton('sourceCode', {
              text: 'Source Code',
              tooltip: 'Edit Source Code',
              onAction: function (_) {
                setShowSourceModal(true)
              },
            })
            editor.ui.registry.addMenuButton(scoreSymbolMenu, {
              text: 'Insert Score Symbols',
              fetch: function (callback) {
                callback([
                  {
                    type: 'nestedmenuitem',
                    text: 'Scale Scores',
                    getSubmenuItems: function () {
                      return scaleValueTypes.map((scale) => ({
                        type: 'menuitem',
                        text: scale,
                        onAction: function () {
                          editor.insertContent(`&nbsp;<b>{{${scale}}}</b>`)
                        },
                      }))
                    },
                  },
                  {
                    type: 'nestedmenuitem',
                    text: 'Competency Scores',
                    getSubmenuItems: function () {
                      return competencyValueTypes.map((competency) => ({
                        type: 'menuitem',
                        text: competency,
                        onAction: function () {
                          editor.insertContent(`&nbsp;<b>{{${competency}}}</b>`)
                        },
                      }))
                    },
                  },
                  {
                    type: 'nestedmenuitem',
                    text: 'Special Values',
                    getSubmenuItems: function () {
                      return specialValueTypes.map((specialValue) => ({
                        type: 'menuitem',
                        text: specialValue,
                        onAction: function () {
                          editor.insertContent(`&nbsp;<b>{{${specialValue}}}</b>`)
                        },
                      }))
                    },
                  },
                ])
              },
            })
          },
          font_formats: 'Poppins; Helvetica; Sans Serif=sans-serif;',
          fontsize_formats:
            '8pt 9px 10px 11px 12px 14px 16px 18px 24px 30px 36px 48px 60px 72px 96px',
          content_style: 'body { font-size: 16px; font-family: Poppins; }',
          skin: 'snow',
          icons: 'thin',
          placeholder: placeholder,

          height: height,
          menubar: false,
          plugins:
            'print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons',
          mobile: {
            plugins:
              'print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons',
          },
          toolbar:
            'scoreSymbolMenu | undo redo | fontselect fontsizeselect formatselect | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | insertfile image media template link anchor codesample | a11ycheck ltr rtl hr | removeformat | sourceCode',
          quickbars_selection_toolbar:
            'scoreSymbolMenu | bold italic | quicklink h2 h3 blockquote | quickimage quicktable | sourceCode',
          toolbar_mode: 'sliding',
          spellchecker_ignore_list: ['Ephox', 'Moxiecode'], // automatic_uploads: false,
          images_upload_handler: async function (blobInfo, success, failure, progress) {
            try {
              const uri = await uploadFileToBlob(blobInfo.blob() as File)
              success(uri)
            } catch (err) {}
          },
          extended_valid_elements: 'span[*]', // Needed to retain spans without attributes these are removed by default
          formats: {
            removeformat: [
              // Configures `clear formatting` to remove specified elements regardless of its attributes
              {selector: 'b,strong,em,i,font,u,strike,s', remove: 'all'},

              // Configures `clear formatting` to remove the class red from spans and if the element then becomes empty i.e has no attributes it gets removed
              {selector: 'span', classes: 'red', remove: 'empty'},

              // Configures `clear formatting` to remove the class green from spans and if the element then becomes empty it's left intact
              {selector: 'span', classes: 'green', remove: 'none'},
            ],
          },
        }}
        onEditorChange={handleEditorChange}
        outputFormat='html'
      />
      <RichTextEditorSourceCodeModal
        show={showSourceModal}
        html={value}
        onCancel={() => setShowSourceModal(false)}
        onSubmit={(html) => {
          handleEditorChange(html)
          setShowSourceModal(false)
        }}
      />
    </div>
  )
}

RichTextEditor.defaultProps = {
  placeholder: '',
  initialValue: '',
  height: 300,
  onChange: () => {},
  value: '',
}
export default RichTextEditor
