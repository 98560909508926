import TwoFactorRequireAlert from './TwoFactorRequireAlert'

const SystemAlerts: React.FC = () => {
  return (
    <div className='' id='pldr-system-alerts'>
      <TwoFactorRequireAlert />
    </div>
  )
}

export default SystemAlerts
