import React from 'react'
import {AsideMenuItem} from '../AsideMenuItem'
import routerItems from '../../../../../app/routing/routerItems'
import {useIntl} from 'react-intl'
import i18nKeys from '../../../../i18n/i18nKeys'

export default function TeamLeaderAsideMenu() {
  const {formatMessage} = useIntl()
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            {formatMessage({id: i18nKeys.TEAM_LEADER_PAGES})}
          </span>
        </div>
      </div>

      <AsideMenuItem
        icon='/media/icons/duotune/communication/com005.svg'
        to={routerItems.TEAM_LEADER_MEMBERS}
        title={formatMessage({id: i18nKeys.TEAM_MEMBERS})}
      />
    </>
  )
}
