import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {ConfirmModalProvider} from '../_metronic/layout/core/ConfirmModalProvider'
import {NotificationProvider} from '../_metronic/layout/core/NotificationProvider'
import AuthInit from './modules/auth/redux/AuthInit'
import AppRoutes from './routing/AppRoutes'
import {ReportContentEditorProvider} from './components/CustomReport/CustomReportContentEditorProvider'

type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <BrowserRouter basename={basename}>
        <I18nProvider>
          <LayoutProvider>
            <ConfirmModalProvider>
              <NotificationProvider>
                <ReportContentEditorProvider>
                  <AuthInit>
                    <AppRoutes />
                  </AuthInit>
                </ReportContentEditorProvider>
              </NotificationProvider>
            </ConfirmModalProvider>
          </LayoutProvider>
        </I18nProvider>
      </BrowserRouter>
    </Suspense>
  )
}

export {App}
