import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import competencyCodes from '../constants/competencyCodes'
import OverallJobFitScores from '../constants/overallJobFitScores'
import ReportContentValueTypes, {
  competencyValueTypes,
  scaleValueTypes,
  specialValueTypes,
} from '../constants/reportContentValueTypes'
import reportScaleFacets from '../constants/reportScaleFacets'
import scaleCodes from '../constants/scaleCodes'
import scoringEngineTypes from '../constants/scoringEngineTypes'
import {ClientSiteJobTopCompetencyModel} from '../models/ClientSiteJobTopCompetencyModel'
import {EEAnswerModel} from '../models/EEAnswerModel'
import {EEAssessmentResultModel} from '../models/EEAssessmentResultModel'
import {ScoreModel} from '../models/ScoreModel'
import {SubscaleModel} from '../models/SubscaleModel'
import {UserModel} from '../models/UserModel'
import stringHelper from '../utils/stringHelper'
import defaultFitScoreLabels from '../constants/defaultFitScoreLabels'
function useResultScore() {
  const allClientSiteJobTopCompetencies: ClientSiteJobTopCompetencyModel[] = useSelector<RootState>(
    ({baseData}) => baseData.clientSiteJobTopCompetencies,
    shallowEqual
  ) as ClientSiteJobTopCompetencyModel[]
  const subScales = useSelector<RootState>(
    (state) => state.baseData.subscales,
    shallowEqual
  ) as SubscaleModel[]
  function getScaleScore(score?: ScoreModel, scaleName?: string) {
    return Math.round(
      score?.scaleScores?.find((item) => item.scaleName === scaleName)?.thetaPercent || 0
    )
  }
  function getScaleScoreByCode(score?: ScoreModel, scaleCode?: number) {
    return Math.round(
      score?.scaleScores?.find((item) => item.scaleCode === scaleCode)?.thetaPercent || 0
    )
  }
  function getCompetencyScore(score?: ScoreModel, competencyName?: string) {
    return (
      Math.round(
        (score?.competencyScores?.find((item) => item.competencyName === competencyName)
          ?.potentialScore || 0) * 1000
      ) / 1000
    )
  }
  function getCompetencyScoreByCode(score?: ScoreModel | null, competencyCode?: number) {
    return (
      Math.round(
        (score?.competencyScores?.find((item) => item.competencyCode === competencyCode)
          ?.potentialScore || 0) * 1000
      ) / 1000
    )
  }
  function getCompetencyPerformanceScore(score?: ScoreModel, competencyName?: string) {
    return (
      Math.round(
        (score?.competencyScores?.find((item) => item.competencyName === competencyName)
          ?.potentialFit || 0) * 1000
      ) / 1000
    )
  }
  function getCompetencyPerformanceScoreByCode(score?: ScoreModel, competencyCode?: number) {
    return (
      Math.round(
        (score?.competencyScores?.find((item) => item.competencyCode === competencyCode)
          ?.potentialFit || 0) * 1000
      ) / 1000
    )
  }

  function getOverallJobFitScoreLabel(
    {overallJobFitScore = -1}: {overallJobFitScore?: OverallJobFitScores},
    user?: UserModel | null,
    jobId?: string
  ) {
    const targetJobId = jobId || user?.jobId
    if (!targetJobId) return ''
    const clientSiteJobScoreSettings = allClientSiteJobTopCompetencies.find(
      (item) => item.jobId === targetJobId && item.clientSiteId === user?.clientSiteId
    )
    const fitScoreLabels = clientSiteJobScoreSettings?.fitScoreLabels
    if (overallJobFitScore === OverallJobFitScores.NO_RESULTS) return ''
    switch (overallJobFitScore) {
      case OverallJobFitScores.HIGH:
        return fitScoreLabels?.high || defaultFitScoreLabels.high
      case OverallJobFitScores.MODERATE_HIGH:
        return fitScoreLabels?.moderateHigh || defaultFitScoreLabels.moderateHigh
      case OverallJobFitScores.MODERATE:
        return fitScoreLabels?.moderate || defaultFitScoreLabels.moderate
      case OverallJobFitScores.LOW:
        return fitScoreLabels?.low || defaultFitScoreLabels.low
      default:
        return ''
    }
  }

  function getFacet(reportScaleId: number, subScaleName: string, eeAnswers: EEAnswerModel[] = []) {
    const subScaleId = subScales.find((item) => item.label === subScaleName)?._id
    if (!subScaleId) return 0
    const correctAnswers = eeAnswers.filter((eeAnswer) => {
      if (eeAnswer.question?.subscale !== subScaleId) return false
      if (eeAnswer.answerIds.length === 0) return false
      const response = eeAnswer.question.options?.find(
        (option) => option._id === eeAnswer.answerIds[0]
      )
      return !!response?.responseValue
    }).length
    const percents =
      reportScaleFacets.find(
        (item) => item.reportScaleCode === reportScaleId && item.scaleDesc === subScaleName
      )?.percents || []
    if (percents.length === 0) return 0
    return Math.round(
      percents[correctAnswers >= percents.length ? percents.length - 1 : correctAnswers] * 100
    )
  }

  function getReportScoreByScoreKey(
    scoreKey: string,
    employee: UserModel | undefined | null,
    eeAssessmentResults: EEAssessmentResultModel[] = [],
    topCompetencyIds: string[] = []
  ): string {
    if (!scoreKey) return '{{Invalid}}'
    const ppaAssessmentResult = eeAssessmentResults.find(
      (item) => item.assessmentType === scoringEngineTypes.PPA
    )
    const pbaAssessmentResult = eeAssessmentResults.find(
      (item) => item.assessmentType === scoringEngineTypes.PBA
    )
    const pfaAssessmentResult = eeAssessmentResults.find(
      (item) => item.assessmentType === scoringEngineTypes.PFA
    )
    if (specialValueTypes.includes(scoreKey as ReportContentValueTypes)) {
      switch (scoreKey) {
        case ReportContentValueTypes.ParticipantName:
          return stringHelper.getUserFullName(employee)
        case ReportContentValueTypes.CompletedDate:
          const latestDate = Math.max(
            ...eeAssessmentResults.map((item) => new Date(item.updatedAt || '').getTime())
          )
          return new Date(latestDate).toLocaleDateString()
        case ReportContentValueTypes.OverallScore:
          return `${employee?.overallJobFitScore || ''}`
        case ReportContentValueTypes.MaxPPAPotentialScore:
          return Math.max(
            0,
            ...(ppaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore * 100
            )
          ).toString()
        case ReportContentValueTypes.MinPPAPotentialScore:
          return Math.min(
            0,
            ...(ppaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore * 100
            )
          ).toString()
        case ReportContentValueTypes.MaxPPAScaleScore:
          return Math.max(
            0,
            ...(ppaAssessmentResult?.score?.scaleScores || []).map((item) => item.thetaPercent)
          ).toString()
        case ReportContentValueTypes.MinPPAScaleScore:
          return Math.min(
            0,
            ...(ppaAssessmentResult?.score?.scaleScores || []).map((item) => item.thetaPercent)
          ).toString()
        case ReportContentValueTypes.MaxPBAScore:
          return Math.max(
            0,
            ...(pbaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore
            )
          ).toString()
        case ReportContentValueTypes.MinPBAScore:
          return Math.min(
            0,
            ...(pbaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore
            )
          ).toString()
        case ReportContentValueTypes.MaxPFAScore:
          return Math.max(
            0,
            ...(pfaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore * 100
            )
          ).toString()
        case ReportContentValueTypes.MinPFAScore:
          return Math.min(
            0,
            ...(pfaAssessmentResult?.score?.competencyScores || []).map(
              (item) => item.potentialScore * 100
            )
          ).toString()
        default:
          return '{{Undefined}}'
      }
    } else if (competencyValueTypes.includes(scoreKey as ReportContentValueTypes)) {
      const score = ppaAssessmentResult?.score?.competencyScores.find(
        (item) => item.competencyCode === competencyCodes[scoreKey]
      )
      return score ? (score.potentialScore * 100).toString() : '{{Undefined}}'
    } else if (scaleValueTypes.includes(scoreKey as ReportContentValueTypes)) {
      const score = ppaAssessmentResult?.score?.scaleScores.find(
        (item) => item.scaleCode === scaleCodes[scoreKey]
      )
      return score ? score.thetaPercent.toString() : '{{Undefined}}'
    }
    return '{{Invalid}}'
  }
  return {
    getScaleScore,
    getScaleScoreByCode,
    getCompetencyScore,
    getCompetencyScoreByCode,
    getCompetencyPerformanceScore,
    getCompetencyPerformanceScoreByCode,
    getFacet,
    getOverallJobFitScoreLabel,
    getReportScoreByScoreKey,
  }
}

export default useResultScore
