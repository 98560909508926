import React, {useEffect} from 'react'
import {AsideDefault} from './components/aside/AsideDefault'
import {Footer} from './components/Footer'
import {HeaderWrapper} from './components/header/HeaderWrapper'
import {ScrollTop} from './components/ScrollTop'
import {PageDataProvider} from './core'
import {Navigate, useLocation} from 'react-router-dom'
import {DrawerMessenger, ActivityDrawer, Main, InviteUsers, UpgradePlan} from '../partials'
import {MenuComponent} from '../assets/ts/components'
import {shallowEqual, useSelector} from 'react-redux'
import {RootState} from '../../setup'
import routerItems from '../../app/routing/routerItems'
import SystemAlerts from './components/system-alerts/SystemAlerts'
import {WithChildrenProps} from '../../types'

const MasterLayout: React.FC<WithChildrenProps> = ({children}) => {
  const isAuthorized = useSelector<RootState>((state) => state.auth.user, shallowEqual)
  const location = useLocation()
  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])
  if (!isAuthorized) return <Navigate to={routerItems.AUTH_LOGIN} replace />
  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid' id='kt_wrapper'>
          <HeaderWrapper />
          {/* <Toolbar /> */}
          <SystemAlerts />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid justify-content-center' id='kt_post'>
              <div className='w-100 px-sm-4 px-xl-5'>{children}</div>
            </div>
          </div>
          <Footer />
        </div>
      </div>

      <ActivityDrawer />
      {/* <ExploreMain /> */}
      <DrawerMessenger />

      <Main />
      <InviteUsers />
      <UpgradePlan />

      <ScrollTop />
    </PageDataProvider>
  )
}

export {MasterLayout}
