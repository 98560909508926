import React from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Navigate, useSearchParams} from 'react-router-dom'
import {RootState} from '../../../setup'
import routerItems from '../../routing/routerItems'
import {WithChildrenProps} from '../../../types'
import {isHrManager} from '../../utils/authHelper'
import {UserModel} from '../../models/UserModel'

const AuthPageLayout: React.FC<WithChildrenProps> = ({children}) => {
  const [param] = useSearchParams()
  const rtUrl = param.get('rtUrl')
  const authorizedUser = useSelector<RootState>((state) => state.auth.user, shallowEqual) as
    | UserModel
    | undefined

  if (authorizedUser)
    return (
      <Navigate
        to={
          rtUrl ||
          (isHrManager(authorizedUser.roles)
            ? routerItems.HR_ADMIN_COMPARE_OVERALL_SCORE
            : routerItems.HOME)
        }
      />
    )
  return <>{children}</>
}

export default AuthPageLayout
