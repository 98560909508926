const competencyCodes: Record<string, number> = {
  AdaptabilityFlexibility: 10,
  BuildingPartnerships: 20,
  BuildingTeams: 30,
  Citizenship: 40,
  ConcernForOthers: 50,
  ConflictResolution: 60,
  ContinuousLearning: 70,
  Cooperation: 80,
  CustomerOrientation: 90,
  DecisionMaking: 100,
  Dependability: 110,
  DetailOrientation: 120,
  EmployeeDevelopment: 130,
  FacilitatingChange: 140,
  FormalPresentation: 150,
  Independence: 160,
  IndustryKnowledge: 170,
  Influence: 180,
  Initiative: 190,
  Innovation: 200,
  InterpersonalSkills: 210,
  Leadership: 220,
  MathSkills: 230,
  Negotiation: 240,
  OralCommunication: 250,
  Persistence: 260,
  PlanningOrganizing: 270,
  ProblemSolving: 280,
  Professionalism: 290,
  ResultsOrientation: 300,
  RiskTaking: 310,
  SelfControl: 320,
  SocialOrientation: 330,
  StressTolerance: 340,
  Teamwork: 350,
  TechnicalKnowledge: 360,
  TrainingTeaching: 370,
  Trustworthiness: 380,
  WorkAttitude: 390,
  WrittenCommunication: 400,
}

export default competencyCodes
