import axios from 'axios'
import apiRouterItems from '../../../constants/apiRouterItems'
import {AuthModel} from '../../../models/AuthModel'
import {UserModel} from '../../../models/UserModel'

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<any>(apiRouterItems.LOGIN_URL, {email, password})
}

// Server should return AuthModel
export function register(userData: Partial<UserModel>) {
  return axios.post<AuthModel>(apiRouterItems.REGISTER_URL, userData)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.put<{result: boolean}>(apiRouterItems.FORGOT_PASSWORD, {email})
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(apiRouterItems.GET_USER_FULL_INFO)
}
