import {useIntl} from 'react-intl'
import {AsideMenuItem} from './AsideMenuItem'
import routerItems from '../../../../app/routing/routerItems'
import ClientManagerAsideMenu from './sub-aside-menus/ClientManagerAsideMenu'
import HrManagerAsideMenu from './sub-aside-menus/HrManagerAsideMenu'
import SuperAdminAsideMenu from './sub-aside-menus/SuperAdminAsideMenu'
import FeaturesAsideMenu from './sub-aside-menus/FeaturesAsideMenu'
import i18nKeys from '../../../i18n/i18nKeys'
import TeamLeaderAsideMenu from './sub-aside-menus/TeamLeaderAsideMenu'
import useUser from '../../../../app/hooks/useUser'

export function AsideMenuMain() {
  const {formatMessage} = useIntl()
  const {
    hasApplicantRole: isApplicant,
    hasEmployeeRole: isEmployee,
    hasManagerRole: isManager,
    hasTeamLeaderRole: isTeamLeader,
    hasHrManagerRole: isHrManager,
    isSuperAdmin,
  } = useUser()
  return (
    <>
      {isApplicant && (
        <AsideMenuItem
          to={routerItems.DASHBOARD}
          icon='/media/icons/duotune/general/gen001.svg'
          title={formatMessage({id: i18nKeys.DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isEmployee && (
        <AsideMenuItem
          to={routerItems.DASHBOARD_EMPLOYEE}
          icon='/media/icons/duotune/general/gen008.svg'
          title={formatMessage({id: i18nKeys.EMPLOYEE_DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isTeamLeader && (
        <AsideMenuItem
          to={routerItems.DASHBOARD_TEAM_LEADER}
          icon='/media/icons/duotune/general/gen024.svg'
          title={formatMessage({id: i18nKeys.TEAM_LEADER_DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isHrManager && (
        <AsideMenuItem
          to={routerItems.DASHBOARD_HR_MANAGER}
          icon='/media/icons/duotune/abstract/abs027.svg'
          title={formatMessage({id: i18nKeys.HR_ADMIN_DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isManager && (
        <AsideMenuItem
          to={routerItems.DASHBOARD_MANAGER}
          icon='/media/icons/duotune/general/gen009.svg'
          title={formatMessage({id: i18nKeys.MANAGER_DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      {isSuperAdmin && (
        <AsideMenuItem
          to={routerItems.DASHBOARD_ADMIN}
          icon='/media/icons/duotune/general/gen032.svg'
          title={formatMessage({id: i18nKeys.ADMIN_DASHBOARD})}
          fontIcon='bi-app-indicator'
        />
      )}
      <FeaturesAsideMenu />
      {isTeamLeader && <TeamLeaderAsideMenu />}
      {isHrManager && <HrManagerAsideMenu />}
      {isManager && <ClientManagerAsideMenu />}
      {isSuperAdmin && <SuperAdminAsideMenu />}
    </>
  )
}
