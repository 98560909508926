export enum Permission {
  Public = 0,
  Authorized = 1,
  Applicant = 11,
  Mentor = 12,
  Employee = 13,
  TeamLeader = 14,
  HrAdmin = 15,
  Manager = 16,
  SuperAdmin = 99
}
export enum UserRole {
  APPLICANT = 'applicant',
  MENTOR = 'mentor',
  EMPLOYEE = 'employee',
  TEAM_LEADER = 'team-leader',
  HR_ADMIN = 'hr-admin',
  MANAGER = 'manager',
  SUPER_ADMIN = 'super-admin',
}

export const userRoleLabels: Record<UserRole, string> = {
  [UserRole.APPLICANT]: 'Applicant',
  [UserRole.MENTOR]: 'Mentor',
  [UserRole.EMPLOYEE]: 'Employee',
  [UserRole.TEAM_LEADER]: 'Team Leader',
  [UserRole.HR_ADMIN]: 'HR Admin',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.SUPER_ADMIN]: 'Super Admin',
}

export const userRoleDescriptions: Record<UserRole, string> = {
  [UserRole.APPLICANT]: 'Applicant',
  [UserRole.MENTOR]: 'Mentor',
  [UserRole.EMPLOYEE]:
    'Employees have minimal access to features other than those needed to apply for a job such as the assessments, interviews and other communications assigned by HR administration',
  [UserRole.TEAM_LEADER]: 'Employee Manager',
  [UserRole.HR_ADMIN]: 'HR Admin',
  [UserRole.MANAGER]: 'Manager',
  [UserRole.SUPER_ADMIN]:
    'The Super admin have full permissions for all actions. This role should be fairly limited for the production version in the furture',
}
export default UserRole
