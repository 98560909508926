import {FC, useRef, useEffect, useState} from 'react'
import {shallowEqual, useSelector, connect, useDispatch, ConnectedProps} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import * as auth from './AuthRedux'
import {baseDataActions} from '../../../../setup/redux/BaseDataRedux'
import {getUserByToken} from './AuthCRUD'
import {RootState} from '../../../../setup'
import {WithChildrenProps} from '../../../../types'

const mapState = (state: RootState) => ({auth: state.auth, baseData: state.baseData})
const connector = connect(mapState, {...auth.userActions, ...baseDataActions})
type PropsFromRedux = ConnectedProps<typeof connector>

const AuthInit: FC<PropsFromRedux & WithChildrenProps> = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken = useSelector<RootState>(({auth}) => auth.accessToken, shallowEqual)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const {data: user} = await getUserByToken()
          dispatch(props.fulfillUser(user))
        }
      } catch (error) {
        if (!didRequest.current) {
          dispatch(props.logout())
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const loadBaseData = async () => {
      try {
        dispatch(props.loadReportTexts())
        dispatch(props.loadDefaultJobCompetencyCuts())
        dispatch(props.loadClientSiteJobTopCompetencies())
        dispatch(props.loadAssessments())
        dispatch(props.loadParentScales())
        dispatch(props.loadSubscales())
        dispatch(props.loadQuestionScores())
        dispatch(props.loadQuestionWorkflows())
        dispatch(props.loadClients())
        dispatch(props.loadJobs())
        dispatch(props.loadThetaPercentiles())
      } catch (err) {}
    }

    loadBaseData()
    if (accessToken) {
      requestUser()
    } else {
      dispatch(props.logout())
      setShowSplashScreen(false)
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default connector(AuthInit)
