import {FC} from 'react'
import PointLeaderFooter from './PointLeaderFooter'

const Footer: FC = () => {
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      <PointLeaderFooter />
    </div>
  )
}

export {Footer}
