import React, {createContext, useContext} from 'react'

import {ToastContainer, ToastOptions, toast} from 'react-toastify'
import {WithChildrenProps} from '../../../types'

export interface NotificationContextModel {
  showNotification: (message: string, option: ToastOptions) => void
  showSuccessNotification: (message: string) => void
  showDangerNotification: (message: string) => void
  showInfoNotification: (message: string) => void
  showDefaultNotification: (message: string) => void
  showWarningNotification: (message: string) => void
}

const NotificationContext = createContext<NotificationContextModel>({
  showNotification: (message: string, option: ToastOptions) => {},
  showSuccessNotification: (message: string) => {},
  showDangerNotification: (message: string) => {},
  showInfoNotification: (message: string) => {},
  showDefaultNotification: (message: string) => {},
  showWarningNotification: (message: string) => {},
})

const NotificationProvider: React.FC<WithChildrenProps> = ({children}) => {
  function showNotification(message: string, option: ToastOptions) {
    toast(message, option)
  }
  function showSuccessNotification(message: string) {
    toast.success(message)
  }
  function showDangerNotification(message: string) {
    toast.error(message)
  }
  function showInfoNotification(message: string) {
    toast.info(message)
  }
  function showDefaultNotification(message: string) {
    toast(message)
  }
  function showWarningNotification(message: string) {
    toast.warning(message)
  }
  const value: NotificationContextModel = {
    showNotification,
    showSuccessNotification,
    showDangerNotification,
    showInfoNotification,
    showDefaultNotification,
    showWarningNotification,
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <ToastContainer />
    </NotificationContext.Provider>
  )
}

export {NotificationContext, NotificationProvider}

export function useNotification() {
  return useContext(NotificationContext)
}
