import {ReportScaleFacetModel} from '../models/ReportScaleFacetModel'
import competencyCodes from './competencyCodes'
import reportScales from './reportScales'

const reportScaleFacets: ReportScaleFacetModel[] = [
  {
    reportScaleCode: reportScales.Agreeableness,
    competencyCode: competencyCodes.Citizenship,
    scaleDesc: 'Caring',
    percents: [0.03, 0.22, 0.54, 0.85, 0.98],
  },
  {
    reportScaleCode: reportScales.Agreeableness,
    competencyCode: competencyCodes.BuildingPartnerships,
    scaleDesc: 'Compassionate',
    percents: [0.04, 0.12, 0.33, 0.81, 0.96],
  },
  {
    reportScaleCode: reportScales.Agreeableness,
    competencyCode: competencyCodes.ConcernForOthers,
    scaleDesc: 'Forgiving',
    percents: [0.11, 0.19, 0.34, 0.73, 0.98],
  },
  {
    reportScaleCode: reportScales.Agreeableness,
    competencyCode: competencyCodes.BuildingTeams,
    scaleDesc: 'Generous',
    percents: [0.16, 0.29, 0.54, 0.83, 0.99],
  },
  {
    reportScaleCode: reportScales.Agreeableness,
    competencyCode: competencyCodes.AdaptabilityFlexibility,
    scaleDesc: 'Good Natured',
    percents: [0.2, 0.42, 0.75, 0.94, 0.99],
  },
  {
    reportScaleCode: reportScales.Conscientiousness,
    competencyCode: competencyCodes.ConflictResolution,
    scaleDesc: 'Avoids Trouble',
    percents: [0.03, 0.15, 0.35, 0.69, 0.98],
  },
  {
    reportScaleCode: reportScales.Conscientiousness,
    competencyCode: competencyCodes.Cooperation,
    scaleDesc: 'Dependable',
    percents: [0.09, 0.19, 0.4, 0.75, 0.97],
  },
  {
    reportScaleCode: reportScales.Conscientiousness,
    competencyCode: competencyCodes.ContinuousLearning,
    scaleDesc: 'Hard Working',
    percents: [0.06, 0.19, 0.3, 0.56, 0.9],
  },
  {
    reportScaleCode: reportScales.Conscientiousness,
    competencyCode: competencyCodes.SocialOrientation,
    scaleDesc: 'Perfection',
    percents: [0.12, 0.28, 0.6, 0.92, 0.98],
  },
  {
    reportScaleCode: reportScales.Conscientiousness,
    competencyCode: 90,
    scaleDesc: 'Rule Following',
    percents: [0.15, 0.22, 0.47, 0.86, 0.99],
  },
  {
    reportScaleCode: reportScales.Drive,
    competencyCode: 150,
    scaleDesc: 'Ambitious',
    percents: [0.08, 0.31, 0.58, 0.83, 0.98],
  },
  {
    reportScaleCode: reportScales.Drive,
    competencyCode: 180,
    scaleDesc: 'Courageous',
    percents: [0.19, 0.41, 0.71, 0.93, 0.99],
  },
  {
    reportScaleCode: reportScales.Drive,
    competencyCode: 160,
    scaleDesc: 'Optimistic',
    percents: [0.14, 0.16, 0.24, 0.48, 0.99],
  },
  {
    reportScaleCode: reportScales.Drive,
    competencyCode: 170,
    scaleDesc: 'Proud',
    percents: [0.08, 0.18, 0.34, 0.56, 0.95],
  },
  {
    reportScaleCode: reportScales.Drive,
    competencyCode: 190,
    scaleDesc: 'Visionary',
    percents: [0.06, 0.16, 0.27, 0.67, 0.98],
  },
  {
    reportScaleCode: reportScales.Extraversion,
    competencyCode: 140,
    scaleDesc: 'Attention Seeking',
    percents: [0.08, 0.18, 0.64, 0.92, 0.99],
  },
  {
    reportScaleCode: reportScales.Extraversion,
    competencyCode: 120,
    scaleDesc: 'Energized by People',
    percents: [0.02, 0.1, 0.48, 0.7, 0.95],
  },
  {
    reportScaleCode: reportScales.Extraversion,
    competencyCode: 110,
    scaleDesc: 'Enjoys Crowd',
    percents: [0.28, 0.41, 0.72, 0.95, 0.99],
  },
  {
    reportScaleCode: reportScales.Extraversion,
    competencyCode: 100,
    scaleDesc: 'Outgoing',
    percents: [0.24, 0.65, 0.81, 0.96, 0.99],
  },
  {
    reportScaleCode: reportScales.Extraversion,
    competencyCode: 130,
    scaleDesc: 'Talkative',
    percents: [0.12, 0.28, 0.59, 0.86, 0.98],
  },
  {
    reportScaleCode: reportScales.Openness,
    competencyCode: 250,
    scaleDesc: 'Innovative',
    percents: [0.09, 0.22, 0.43, 0.87, 0.98],
  },
  {
    reportScaleCode: reportScales.Openness,
    competencyCode: 240,
    scaleDesc: 'Learner',
    percents: [0.12, 0.36, 0.61, 0.9, 0.99],
  },
  {
    reportScaleCode: reportScales.Openness,
    competencyCode: 210,
    scaleDesc: 'Open-minded',
    percents: [0.15, 0.28, 0.38, 0.67, 0.99],
  },
  {
    reportScaleCode: reportScales.Openness,
    competencyCode: 220,
    scaleDesc: 'Rational',
    percents: [0.12, 0.19, 0.41, 0.62, 0.91],
  },
  {
    reportScaleCode: reportScales.Openness,
    competencyCode: 230,
    scaleDesc: 'Strategic',
    percents: [0.05, 0.21, 0.39, 0.66, 0.93],
  },
  {
    reportScaleCode: reportScales.Sensibility,
    competencyCode: 300,
    scaleDesc: 'Calmness',
    percents: [0.11, 0.23, 0.48, 0.74, 0.91],
  },
  {
    reportScaleCode: reportScales.Sensibility,
    competencyCode: 270,
    scaleDesc: 'Confident',
    percents: [0.12, 0.31, 0.55, 0.85, 0.98],
  },
  {
    reportScaleCode: reportScales.Sensibility,
    competencyCode: 280,
    scaleDesc: 'Joyful',
    percents: [0.15, 0.23, 0.41, 0.81, 0.95],
  },
  {
    reportScaleCode: reportScales.Sensibility,
    competencyCode: 290,
    scaleDesc: 'Secure',
    percents: [0.12, 0.31, 0.64, 0.85, 0.99],
  },
  {
    reportScaleCode: reportScales.Sensibility,
    competencyCode: 260,
    scaleDesc: 'Trusting',
    percents: [0.05, 0.2, 0.39, 0.68, 0.98],
  },
  {
    reportScaleCode: reportScales.Derailment,
    competencyCode: 310,
    scaleDesc: 'Explosive',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales.Derailment,
    competencyCode: 320,
    scaleDesc: 'Isolating',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Tactical Intelligence'],
    competencyCode: 360,
    scaleDesc: 'Quantitative_2',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Tactical Intelligence'],
    competencyCode: 370,
    scaleDesc: 'Spatial_2',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Tactical Intelligence'],
    competencyCode: 390,
    scaleDesc: 'Verbal_2',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Strategic Intelligence'],
    competencyCode: 410,
    scaleDesc: 'Quantitative_1',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Strategic Intelligence'],
    competencyCode: 380,
    scaleDesc: 'Spatial_1',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
  {
    reportScaleCode: reportScales['Strategic Intelligence'],
    competencyCode: 400,
    scaleDesc: 'Verbal_1',
    percents: [0.05, 0.14, 0.29, 0.43, 0.57, 0.71, 0.86, 1],
  },
]

export default reportScaleFacets
