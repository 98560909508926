const scaleCodes: Record<string, number> = {
  Agreeableness: 10,
  Conscientiousness: 20,
  Derailment: 30,
  Extraversion: 40,
  Driver: 50,
  Openness: 60,
  Sensibility: 70,
  StrategicIntelligence: 80,
  EmotionalIntelligence: 90,
  TacticalIntelligence: 100,
}

export default scaleCodes
