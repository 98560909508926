import {AxiosStatic} from 'axios'

const API_PREFIX = '/api/'

export default function setupAxios(axios: AxiosStatic, store: any) {
  axios.interceptors.request.use(
    (config: any) => {
      const {
        auth: {accessToken},
      } = store.getState()

      config.baseURL = process.env.REACT_APP_API_URL + API_PREFIX
      if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
  axios.interceptors.response.use(
    (response) => {
      // If the response is successful (status code 2xx), return it
      return response
    }
    // async (error) => {
    //   // If the response failed, check if it's a server crash or network issue
    //   if (!error.response) {
    //     // Prepare the data to send to the watchdog server
    //     const crashInfo = {
    //       timestamp: new Date().toISOString(),
    //       errorMessage: error.message,
    //       serverStatus: 'crashed',
    //       endpoint: error.config.url, // The endpoint that failed
    //     }

    //     try {
    //       const watchdogServerURL = process.env.REACT_APP_WATCHDOG_URL + API_PREFIX + 'watchdog'
    //       // Send the crash info to the watchdog server
    //       axios.post(watchdogServerURL, crashInfo)

    //       console.log('Crash info successfully sent to the watchdog server.')
    //     } catch (watchdogError) {
    //       console.error('Failed to report crash to the watchdog server:', watchdogError)
    //     }
    //     // Network error or the server is down (crash)
    //     window.location.href = '/maintenance.html'
    //     return Promise.reject(new Error('Server is down or unreachable.'))
    //   }

    //   // Reject the error so it can be caught in catch blocks
    //   return Promise.reject(error)
    // }
  )
}
