import { toAbsoluteUrl } from '../../_metronic/helpers'
import { UserModel } from '../models/UserModel'
import { v4 as uuid } from 'uuid'

function getUserInitial(user?: UserModel | null) {
  const fullName = getUserFullName(user)
  return fullName.charAt(0).toUpperCase()
}
function getUserFullName(user?: UserModel | null) {
  if (user?.firstName && user?.lastName) {
    return user.firstName + ' ' + user.lastName
  }
  if (user?.fullName) return user.fullName
  if (user?.username) return user.username
  return ''
}
function getUserAvatar(pic: string = '') {
  return pic || toAbsoluteUrl('/media/avatars/blank.png')
}
function htmlToText(html: string = ''): string {
  return (html + '').replace(/<[^>]+>/g, '')
}
function summaryText(text: string = '', length: number = 100) {
  const str = htmlToText(text)
  return str.substring(0, length) + (str.length > length ? ' ...' : '')
}
function convertCamelCaseToSpaceSeparated(cameCaseString: string = '') {
  const result = cameCaseString.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

function convertUrlQueryToJson(query: string = '') {
  const strQuery = query.substring(1) || ''
  return Object.fromEntries(new URLSearchParams(strQuery))
}

function convertJsonToUrlQuery(json: { [key: string]: string }) {
  return Object.keys(json)
    .filter((key) => !!json[key].length)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(json[key]))
    .join('&')
}

function generateFakeEmail(firstName: string, lastName: string, uniqueId: string) {
  return `${firstName}.${lastName}${uniqueId}@fake.email`.toLowerCase()
}

function getLocalDateString(date?: string | Date | null) {
  if (!date) return
  return new Date(date).toLocaleDateString()
}

function getLocalDateTimeString(date?: string | Date | null) {
  if (!date) return
  return new Date(date).toLocaleString()
}

function generateNewUuid() {
  return uuid()
}

function camelCaseToTitle(camelCaseString = '') {
  return camelCaseString.replace(/([A-Z]+)/g, ' $1').replace(/([A-Z][a-z])/g, ' $1')
}

function replaceAll(srcString = '', matchString = '', replaceString = '') {
  const re = new RegExp(matchString, 'g')
  return srcString.replace(re, replaceString)
}

async function convertImageToBase64(url: string): Promise<string> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        resolve(reader.result as string);
      };
      reader.onerror = reject;
    });
  } catch (error) {
    console.error("Error converting image to Base64:", error);
    return '';
  }
}
async function convertImageToBuffer(url: string): Promise<Buffer | null> {
  try {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return Buffer.from(buffer)
  } catch (error) {
    console.error("Error converting image to buffer:", error);
    return null;
  }
}

const stringHelper = {
  convertImageToBase64,
  convertImageToBuffer,
  getUserFullName,
  getUserInitial,
  htmlToText,
  summaryText,
  getUserAvatar,
  convertCamelCaseToSpaceSeparated,
  convertUrlQueryToJson,
  convertJsonToUrlQuery,
  generateFakeEmail,
  getLocalDateString,
  getLocalDateTimeString,
  generateNewUuid,
  camelCaseToTitle,
  replaceAll,
}

export default stringHelper
