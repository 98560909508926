const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

type Props = {
  selectedLang: 'de' | 'en' | 'es' | 'fr' | 'ja' | 'zh'
}
const initialState: Props = {
  selectedLang: 'en',
}

export function getLanguageConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY)
  if (ls) {
    try {
      return (JSON.parse(ls) as Props).selectedLang
    } catch (er) {
      console.error(er)
    }
  }
  return initialState.selectedLang
}

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({selectedLang: lang}))
  window.location.reload()
}
