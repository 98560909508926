const i18nKeys = {
  TRANSLATOR_SELECT: 'TRANSLATOR.SELECT',
  MENU_NEW: 'MENU.NEW',
  MENU_ACTIONS: 'MENU.ACTIONS',
  MENU_CREATE_POST: 'MENU.CREATE_POST',
  MENU_PAGES: 'MENU.PAGES',
  MENU_FEATURES: 'MENU.FEATURES',
  MENU_APPS: 'MENU.APPS',
  MENU_DASHBOARD: 'MENU.DASHBOARD',
  AUTH_GENERAL_OR: 'AUTH.GENERAL.OR',
  AUTH_GENERAL_SUBMIT_BUTTON: 'AUTH.GENERAL.SUBMIT_BUTTON',
  AUTH_GENERAL_NO_ACCOUNT: 'AUTH.GENERAL.NO_ACCOUNT',
  AUTH_GENERAL_SIGNUP_BUTTON: 'AUTH.GENERAL.SIGNUP_BUTTON',
  AUTH_GENERAL_FORGOT_BUTTON: 'AUTH.GENERAL.FORGOT_BUTTON',
  AUTH_GENERAL_BACK_BUTTON: 'AUTH.GENERAL.BACK_BUTTON',
  AUTH_GENERAL_PRIVACY: 'AUTH.GENERAL.PRIVACY',
  AUTH_GENERAL_LEGAL: 'AUTH.GENERAL.LEGAL',
  AUTH_GENERAL_CONTACT: 'AUTH.GENERAL.CONTACT',
  AUTH_LOGIN_TITLE: 'AUTH.LOGIN.TITLE',
  AUTH_LOGIN_BUTTON: 'AUTH.LOGIN.BUTTON',
  AUTH_FORGOT_TITLE: 'AUTH.FORGOT.TITLE',
  AUTH_FORGOT_DESC: 'AUTH.FORGOT.DESC',
  AUTH_FORGOT_SUCCESS: 'AUTH.FORGOT.SUCCESS',
  AUTH_REGISTER_TITLE: 'AUTH.REGISTER.TITLE',
  AUTH_REGISTER_DESC: 'AUTH.REGISTER.DESC',
  AUTH_REGISTER_SUCCESS: 'AUTH.REGISTER.SUCCESS',
  AUTH_INPUT_EMAIL: 'AUTH.INPUT.EMAIL',
  AUTH_INPUT_FULLNAME: 'AUTH.INPUT.FULLNAME',
  AUTH_INPUT_PASSWORD: 'AUTH.INPUT.PASSWORD',
  AUTH_INPUT_CONFIRM_PASSWORD: 'AUTH.INPUT.CONFIRM_PASSWORD',
  AUTH_INPUT_USERNAME: 'AUTH.INPUT.USERNAME',
  AUTH_VALIDATION_INVALID: 'AUTH.VALIDATION.INVALID',
  AUTH_VALIDATION_REQUIRED: 'AUTH.VALIDATION.REQUIRED',
  AUTH_VALIDATION_MIN_LENGTH: 'AUTH.VALIDATION.MIN_LENGTH',
  AUTH_VALIDATION_AGREEMENT_REQUIRED: 'AUTH.VALIDATION.AGREEMENT_REQUIRED',
  AUTH_VALIDATION_NOT_FOUND: 'AUTH.VALIDATION.NOT_FOUND',
  AUTH_VALIDATION_INVALID_LOGIN: 'AUTH.VALIDATION.INVALID_LOGIN',
  AUTH_VALIDATION_REQUIRED_FIELD: 'AUTH.VALIDATION.REQUIRED_FIELD',
  AUTH_VALIDATION_MIN_LENGTH_FIELD: 'AUTH.VALIDATION.MIN_LENGTH_FIELD',
  AUTH_VALIDATION_MAX_LENGTH_FIELD: 'AUTH.VALIDATION.MAX_LENGTH_FIELD',
  AUTH_VALIDATION_INVALID_FIELD: 'AUTH.VALIDATION.INVALID_FIELD',
  ECOMMERCE_COMMON_SELECTED_RECORDS_COUNT: 'ECOMMERCE.COMMON.SELECTED_RECORDS_COUNT',
  ECOMMERCE_COMMON_ALL: 'ECOMMERCE.COMMON.ALL',
  ECOMMERCE_COMMON_SUSPENDED: 'ECOMMERCE.COMMON.SUSPENDED',
  ECOMMERCE_COMMON_ACTIVE: 'ECOMMERCE.COMMON.ACTIVE',
  ECOMMERCE_COMMON_FILTER: 'ECOMMERCE.COMMON.FILTER',
  ECOMMERCE_COMMON_BY_STATUS: 'ECOMMERCE.COMMON.BY_STATUS',
  ECOMMERCE_COMMON_BY_TYPE: 'ECOMMERCE.COMMON.BY_TYPE',
  ECOMMERCE_COMMON_BUSINESS: 'ECOMMERCE.COMMON.BUSINESS',
  ECOMMERCE_COMMON_INDIVIDUAL: 'ECOMMERCE.COMMON.INDIVIDUAL',
  ECOMMERCE_COMMON_SEARCH: 'ECOMMERCE.COMMON.SEARCH',
  ECOMMERCE_COMMON_IN_ALL_FIELDS: 'ECOMMERCE.COMMON.IN_ALL_FIELDS',
  ECOMMERCE_ECOMMERCE: 'ECOMMERCE.ECOMMERCE',
  ECOMMERCE_CUSTOMERS_CUSTOMERS: 'ECOMMERCE.CUSTOMERS.CUSTOMERS',
  ECOMMERCE_CUSTOMERS_CUSTOMERS_LIST: 'ECOMMERCE.CUSTOMERS.CUSTOMERS_LIST',
  ECOMMERCE_CUSTOMERS_NEW_CUSTOMER: 'ECOMMERCE.CUSTOMERS.NEW_CUSTOMER',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_SIMPLE_TITLE:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_SIMPLE_DESCRIPTION:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_SIMPLE_WAIT_DESCRIPTION:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_SIMPLE_MESSAGE:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_MULTY_TITLE:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_MULTY_DESCRIPTION:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_MULTY_WAIT_DESCRIPTION:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION',
  ECOMMERCE_CUSTOMERS_DELETE_CUSTOMER_MULTY_MESSAGE:
    'ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE',
  ECOMMERCE_CUSTOMERS_UPDATE_STATUS_TITLE: 'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.TITLE',
  ECOMMERCE_CUSTOMERS_UPDATE_STATUS_MESSAGE: 'ECOMMERCE.CUSTOMERS.UPDATE_STATUS.MESSAGE',
  ECOMMERCE_CUSTOMERS_EDIT_UPDATE_MESSAGE: 'ECOMMERCE.CUSTOMERS.EDIT.UPDATE_MESSAGE',
  ECOMMERCE_CUSTOMERS_EDIT_ADD_MESSAGE: 'ECOMMERCE.CUSTOMERS.EDIT.ADD_MESSAGE',

  DASHBOARD: 'DASHBOARD',
  EMPLOYEE_DASHBOARD: 'EMPLOYEE_DASHBOARD',
  TEAM_LEADER_DASHBOARD: 'TEAM_LEADER_DASHBOARD',
  HR_ADMIN_DASHBOARD: 'HR_ADMIN_DASHBOARD',
  MANAGER_DASHBOARD: 'MANAGER_DASHBOARD',
  ADMIN_DASHBOARD: 'ADMIN_DASHBOARD',
  FEATURES: 'FEATURES',
  MY_TODO_LIST: 'MY_TODO_LIST',
  MY_ASSESSMENTS: 'MY_ASSESSMENTS',
  MY_ASSESSMENT_RESULTS: 'MY_ASSESSMENT_RESULTS',
  MY_SCHEDULES: 'MY_SCHEDULES',
  MY_REPORTS: 'MY_REPORTS',
  TEAM_LEADER_PAGES: 'TEAM_LEADER_PAGES',
  HR_ADMIN_PAGES: 'HR_ADMIN_PAGES',
  COMPANY_EMPLOYEES: 'COMPANY_EMPLOYEES',
  TEAM_MEMBERS: 'TEAM_MEMBERS',
  COMPANY_SITES_JOBS: 'COMPANY_SITES_JOBS',
  ASSIGN_ASSESSMENT: 'ASSIGN_ASSESSMENT',
  COMPARE_CANDIDATES: 'COMPARE_CANDIDATES',
  COMPARE_GROUP: 'COMPARE_GROUP',
  TEAM_MANAGEMENT: 'TEAM_MANAGEMENT',
  EMPLOYEE_ASSESSMENT_RESULT: 'EMPLOYEE_ASSESSMENT_RESULT',
  CLIENT_MANAGERS_PAGES: 'CLIENT_MANAGERS_PAGES',
  MY_COMPANY: 'MY_COMPANY',
  COMPANY_JOBS: 'COMPANY_JOBS',
  COMPANY_NOTIFICATIONS: 'COMPANY_NOTIFICATIONS',
  COMPANY_NEWS: 'COMPANY_NEWS',
  LATEST_COMPANY_NEWS: 'LATEST_COMPANY_NEWS',
  COMPANY_TRENDS: 'COMPANY_TRENDS',
  ASSESSMENTS: 'ASSESSMENTS',
  MY_PROFILE: 'MY_PROFILE',
  LANGUAGE: 'LANGUAGE',
  SIGN_OUT: 'SIGN_OUT',
  SING_IN_TO_POINTLEADER: 'SING_IN_TO_POINTLEADER',
  NEW_HERE: 'NEW_HERE',
  CREATE_AN_ACCOUNT: 'CREATE_AN_ACCOUNT',
  EMAIL: 'EMAIL',
  PASSWORD: 'PASSWORD',
  FORGOT_PASSWORD: 'FORGOT_PASSWORD',
  CONTINUE: 'CONTINUE',
  CONTACT_US: 'CONTACT_US',
  FAQS: 'FAQS',
  ABOUT: 'ABOUT',
  TERMS: 'TERMS',
  PRIVACY: 'PRIVACY',
  REGISTER: 'REGISTER',
  FIRST_NAME: 'FIRST_NAME',
  LAST_NAME: 'LAST_NAME',
  CONFIRM_PASSWORD: 'CONFIRM_PASSWORD',
  PASSWORD_CONFIRMATION: 'PASSWORD_CONFIRMATION',
  I_AGREE_THE_TERMS_AND_CONDITIONS: 'I_AGREE_THE_TERMS_AND_CONDITIONS',
  NEXT: 'NEXT',
  CANCEL: 'CANCEL',
  LOGIN: 'LOGIN',
  ASSIGNED_ASSESSMENTS: 'ASSIGNED_ASSESSMENTS',
  USER_ACTIVITIES: 'USER_ACTIVITIES',
  LATEST_TRENDS_OF_INTEREST_TO_APPLICANTS: 'LATEST_TRENDS_OF_INTEREST_TO_APPLICANTS',
  LATEST_COMPLETED_ASSESSMENTS: 'LATEST_COMPLETED_ASSESSMENTS',
  SEE_ALL: 'SEE_ALL',
  ADD_NEW: 'ADD_NEW',
  NOTIFICATIONS: 'NOTIFICATIONS',
  TITLE: 'TITLE',
  CONTENTS: 'CONTENTS',
  SCHEDULE_AT: 'SCHEDULE_AT',
  COMPLETE_TASK_BY: 'COMPLETE_TASK_BY',
  ACTIONS: 'ACTIONS',
  PAGE: 'PAGE',
  PAGE_SIZE: 'PAGE_SIZE',
  ITEM_1_TO_10_OF_100: 'ITEM_1_TO_10_OF_100',
  SUB_ASSESSMENT: 'SUB_ASSESSMENT',
  EVALUATOR: 'EVALUATOR',
  DATE: 'DATE',
  REGISTERED_COMPANY_EMPLOYEES: 'REGISTERED_COMPANY_EMPLOYEES',
  ALL_50_EMPLOYEES: 'ALL_50_EMPLOYEES',
  VIEW_POINTLEADER_REPORT: 'VIEW_POINTLEADER_REPORT',
  VIEW_TALENT_SUMMARY: 'VIEW_TALENT_SUMMARY',
  EVALUATE_TALENT_IN_PBA: 'EVALUATE_TALENT_IN_PBA',
  USER_NAME: 'USER_NAME',
  ROLES: 'ROLES',
  CONTACT_INFO: 'CONTACT_INFO',
  CLIENT_SITE: 'CLIENT_SITE',
  JOB: 'JOB',
  REGISTERED_AT: 'REGISTERED_AT',
  COMPANY: 'COMPANY',
  SITE: 'SITE',
  EMPLOYEE: 'EMPLOYEE',
  EMPLOYEES: 'EMPLOYEES',
  SEARCH_: 'SEARCH_',
  SELECT_CLIENT: 'SELECT_CLIENT',
  SELECT_SITE: 'SELECT_SITE',
  SELECT_JOB: 'SELECT_JOB',
  FILTER_BY: 'FILTER_BY',
  ALLOW_SHOW_ASSESSMENT_RESULT_TO_EMPLOYEE: 'ALLOW_SHOW_ASSESSMENT_RESULT_TO_EMPLOYEE',
  REQUIRE_EMAIL_VERIFICATION_OF_EMPLOYEE_IN_REGISTRATION:
    'REQUIRE_EMAIL_VERIFICATION_OF_EMPLOYEE_IN_REGISTRATION',
  WELCOME_TEXT: 'WELCOME_TEXT',
  WELCOME_TEXT_FOR_APPLICANTS: 'WELCOME_TEXT_FOR_APPLICANTS',
  COMPANY_LOGO: 'COMPANY_LOGO',
  UPDATE_CLIENT: 'UPDATE_CLIENT',
  MANAGE_SITES_JOBS_IN_AMERITECH: 'MANAGE_SITES_JOBS_IN_AMERITECH',
  SITES: 'SITES',
  JOB_DESCRIPTION: 'JOB_DESCRIPTION',
  ONET_CODE: 'ONET_CODE',
  JOB_NAME: 'JOB_NAME',
  JOB_CODE: 'JOB_CODE',
  NOTIFICATION_NAME: 'NOTIFICATION_NAME',
  NOTIFICATION_CONTENT: 'NOTIFICATION_CONTENT',
  ENTER_NOTIFICATION_NAME: 'ENTER_NOTIFICATION_NAME',
  ENTER_NOTIFICATION_CONTENT: 'ENTER_NOTIFICATION_CONTENT',
  MANAGED_BY: 'MANAGED_BY',
  NEWS_TITLE: 'NEWS_TITLE',
  PROFILE_DETAILS: 'PROFILE_DETAILS',
  OVERVIEW: 'OVERVIEW',
  SETTINGS: 'SETTINGS',
  EDIT_PROFILE: 'EDIT_PROFILE',
  PROFILE_COMPLETION: 'PROFILE_COMPLETION',
  FOLLOW: 'FOLLOW',
  AVATAR: 'AVATAR',
  POINTLEADER_PREDICTIVE_ANALYTICS: 'POINTLEADER_PREDICTIVE_ANALYTICS',
  POINTLEADER_TOOLS: 'POINTLEADER_TOOLS',
  POINTLEADER_POTENTIAL_ASSESSMENT: 'POINTLEADER_POTENTIAL_ASSESSMENT',
  POINTLEADER_REPORT: 'POINTLEADER_REPORT',
  AGREEABLENESS_PPA_RESULTS: 'AGREEABLENESS_PPA_RESULTS',
  AGREEABLENESS_IMPACT_OF_RESULTS: 'AGREEABLENESS_IMPACT_OF_RESULTS',
  COPYRIGHT_2022_POINTLEADER_PREDICTIVE_ANALYTICS:
    'COPYRIGHT_2022_POINTLEADER_PREDICTIVE_ANALYTICS',
  SUMMARY_OF_PPA_RESULTS: 'SUMMARY_OF_PPA_RESULTS',
  PERFORMANCE_HOW_ARE_YOU_DOING: 'PERFORMANCE_HOW_ARE_YOU_DOING',
  TALENT_SUMMARY: 'TALENT_SUMMARY',
  DEVELOPMENT_REFLECTING_ON_WHAT_YOU_VE_LEARNED: 'DEVELOPMENT_REFLECTING_ON_WHAT_YOU_VE_LEARNED',
  DEVELOPMENT_HOW_DO_YOU_PLAN_ON_DEVELOPING: 'DEVELOPMENT_HOW_DO_YOU_PLAN_ON_DEVELOPING',
  THANK_YOU: 'THANK_YOU',
  TRENDS: 'TRENDS',
  NEW: 'NEW',
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  MENTORS: 'MENTORS',
  PERSONAL_CHARACTERISTICS: 'PERSONAL_CHARACTERISTICS',
  SCALE_SCORES: 'SCALE_SCORES',
  POTENTIAL: 'POTENTIAL',
  POTENTIAL_FIT_TO_JOB_COMPETENCY: 'POTENTIAL_FIT_TO_JOB_COMPETENCY',
  APPLICANTS: 'APPLICANTS',
  CLIENT: 'CLIENT',
  JOB_FIT_SCORE: 'JOB_FIT_SCORE',
  REGISTERED_JOBS: 'REGISTERED_JOBS',
  UPDATE: 'UPDATE',
  CREATE: 'CREATE',
  FINISH: 'FINISH',
  YOU_COMPLETED_ASSESSMENT: 'YOU_COMPLETED_ASSESSMENT',
  CONGRATULATIONS: 'CONGRATULATIONS',
  CALCULATING_SCORE: 'CALCULATING_SCORE',
  COMPLETED_ASSESSMENTS: 'COMPLETED_ASSESSMENTS',
  ASSESSMENT_RESULTS: 'ASSESSMENT_RESULTS',
  CONSCIENTIOUSNESS: 'CONSCIENTIOUSNESS',
  RESULTS: 'RESULTS',
  IMPACT_OF_RESULTS: 'IMPACT_OF_RESULTS',
  DRIVE: 'DRIVE',
  EXTRAVERSION: 'EXTRAVERSION',
  OPENNESS_TO_EXPERIENCE: 'OPENNESS_TO_EXPERIENCE',
  SENSIBILITY: 'SENSIBILITY',
  STRATEGIC_INTELLIGENCE: 'STRATEGIC_INTELLIGENCE',
  TACTICAL_INTELLIGENCE: 'TACTICAL_INTELLIGENCE',
  EMOTIONAL_INTELLIGENCE: 'EMOTIONAL_INTELLIGENCE',
  DERAILMENT: 'DERAILMENT',
  FOCUS_WHAT_YOUR_PRIORITIZE: 'FOCUS_WHAT_YOUR_PRIORITIZE',
  ASSIGNER: 'ASSIGNER',
}

export default i18nKeys
