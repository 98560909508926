import React from 'react'
import {AsideMenuItemWithSub} from '../AsideMenuItemWithSub'
import {AsideMenuItem} from '../AsideMenuItem'
import routerItems from '../../../../../app/routing/routerItems'

export default function SuperAdminAsideMenu() {
  return (
    <>
      <div className='menu-item'>
        <div className='menu-content pt-5 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
            Super Admin Pages
          </span>
        </div>
      </div>
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen051.svg'
        to={routerItems.AUDIT_LOGS}
        title='Admin Audit Logs'
      />

      <AsideMenuItemWithSub
        to={routerItems.USERS}
        title='User Management'
        icon='/media/icons/duotune/communication/com006.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to={routerItems.USERS_MANAGEMENT} title='User List' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        icon='/media/icons/duotune/general/gen022.svg'
        to={routerItems.SUPER_ADMIN_EMPLOYEE_ASSESSMENT_RESULTS}
        title='Employee Assessment Result'
      />
      <AsideMenuItemWithSub
        to={routerItems.CLIENTS}
        title='Clients'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/general/gen049.svg'
      >
        <AsideMenuItem
          to={routerItems.CLIENTS_MANAGEMENT}
          title='Manage Clients'
          hasBullet={true}
        />
        <AsideMenuItem to={routerItems.CLIENTS_NEW} title='New Client' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={routerItems.ASSESSMENTS}
        title='Assessment'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil016.svg'
      >
        <AsideMenuItem
          to={routerItems.ASSESSMENTS_MANAGEMENT}
          title='Manage Assessments'
          hasBullet={true}
        />
        <AsideMenuItem to={routerItems.ASSESSMENTS_NEW} title='New Assessment' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={routerItems.QUESTIONS}
        title='Question'
        fontIcon='bi-layers'
        icon='/media/icons/duotune/general/gen046.svg'
      >
        <AsideMenuItem
          to={routerItems.QUESTIONS_MANAGEMENT}
          title='Manage Questions'
          hasBullet={true}
        />
        <AsideMenuItem to={routerItems.QUESTIONS_NEW} title='New Question' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to={routerItems.INTERNAL_REPORTS}
        title={'Internal Report Assign Management'}
        icon='/media/icons/duotune/files/fil003.svg'
      />
      <AsideMenuItem
        to={routerItems.CUSTOM_REPORTS}
        title={'Custom Reports Management'}
        icon='/media/icons/duotune/files/fil004.svg'
      />
      <AsideMenuItemWithSub
        to={routerItems.REPORTS}
        title='PointLeader Reports'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/files/fil008.svg'
      >
        <AsideMenuItem to={routerItems.REPORTS_CONTENTS} title='Report Contents' hasBullet={true} />
        <AsideMenuItem to={routerItems.REPORTS_BUILDER} title='Report Builder' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItem
        to={routerItems.JOBS}
        title='Job Management'
        icon='/media/icons/duotune/general/gen020.svg'
      />
      <AsideMenuItem
        to={routerItems.FAQ_MANAGEMENT}
        title='FAQs Management'
        icon='/media/icons/duotune/general/gen030.svg'
      />
      <AsideMenuItem
        to={routerItems.BASE_DATA}
        title='Base Data Management'
        icon='/media/icons/duotune/coding/cod001.svg'
      />
      <AsideMenuItemWithSub
        to={routerItems.MODULES}
        title='Module'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/abstract/abs014.svg'
      >
        <AsideMenuItem to={routerItems.MODULES_MANAGEMENT} title='Modules' hasBullet={true} />
        <AsideMenuItem to={routerItems.MODULES_NEW} title='New Module' hasBullet={true} />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to={routerItems.APPLICATIONS}
        title='Batteries'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/abstract/abs019.svg'
      >
        <AsideMenuItem
          to={routerItems.APPLICATIONS_MANAGEMENT}
          title='Manage Battery'
          hasBullet={true}
        />
        <AsideMenuItem to={routerItems.APPLICATIONS_NEW} title='New Battery' hasBullet={true} />
      </AsideMenuItemWithSub>
    </>
  )
}
