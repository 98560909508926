import {ParentScaleModel} from '../../app/models/ParentScaleModel'
import storage from 'redux-persist/lib/storage'
import {put, takeLatest} from 'redux-saga/effects'
import {ActionWithPayload} from '../../app/modules/auth'
import {persistReducer} from 'redux-persist'
import axios from 'axios'
import apiRouterItems from '../../app/constants/apiRouterItems'
import {SubscaleModel} from '../../app/models/SubscaleModel'
import {QuestionScoreModel} from '../../app/models/QuestionScoreModel'
import {QuestionWorkflowModel} from '../../app/models/QuestionWorkflowModel'
import {AssessmentModel} from '../../app/models/AssessmentModel'
import {ClientModel} from '../../app/models/ClientModel'
import {JobModel} from '../../app/models/JobModel'
import parentScaleTypes from '../../app/constants/parentScaleTypes'
import {CompetencyCutModel} from '../../app/models/CompetencyCutModel'
import {ReportTextModel} from '../../app/models/ReportTextModel'
import {ThetaPercentileModel} from '../../app/models/ThetaPercentileModel'
import {ClientSiteJobTopCompetencyModel} from '../../app/models/ClientSiteJobTopCompetencyModel'

export const baseDataActionTypes = {
  LoadAssessments: 'Load Assessments Action',
  LoadedAssessments: 'Loaded Assessments Action',
  LoadParentScales: 'Load ParentScales Action',
  LoadedParentScales: 'Loaded ParentScales Action',
  LoadSubscales: 'Load Subscales Action',
  LoadedSubscales: 'Loaded Subscales Action',
  LoadQuestionScores: 'Load QuestionScoreTypes Action',
  LoadedQuestionScores: 'Loaded QuestionScoreTypes Action',
  LoadQuestionWorkflows: 'Load QuestionWorkflowTypes Action',
  LoadedQuestionWorkflows: 'Loaded QuestionWorkflowTypes Action',
  LoadClients: 'Load Clients Action',
  LoadedClients: 'Loaded Clients Action',
  LoadJobs: 'Load Jobs Action',
  LoadedJobs: 'Loaded Jobs Action',
  LoadDefaultJobCompetencyCuts: 'Load Default Job Competency Cuts Action',
  LoadedDefaultJobCompetencyCuts: 'Loaded Default Job Competency Cuts Action',
  LoadClientSiteJobTopCompetencies: 'Load ClientSite Job Top Competencies Action',
  LoadedClientSiteJobTopCompetencies: 'Loaded ClientSite Job Top Competencies Action',

  LoadReportTexts: 'Load ReportTexts Action',
  LoadedReportTexts: 'Loaded ReportTexts Action',

  LoadThetaPercentiles: 'Load ThetaPercentiles Action',
  LoadedThetaPercentiles: 'Loaded ThetaPercentiles Action',
}
export interface IBaseDataState {
  assessments: AssessmentModel[]
  scales: ParentScaleModel[]
  competencies: ParentScaleModel[]
  subscales: SubscaleModel[]
  questionScores: QuestionScoreModel[]
  questionWorkflows: QuestionWorkflowModel[]
  clients: ClientModel[]
  jobs: JobModel[]
  defaultJobCompetencyCuts: CompetencyCutModel[]
  clientSiteJobTopCompetencies: ClientSiteJobTopCompetencyModel[]
  reportTexts: ReportTextModel[]
  thetaPercentiles: ThetaPercentileModel[]
}

const initialBaseDataState: IBaseDataState = {
  assessments: [],
  scales: [],
  competencies: [],
  subscales: [],
  questionScores: [],
  questionWorkflows: [],
  clients: [],
  jobs: [],
  defaultJobCompetencyCuts: [],
  clientSiteJobTopCompetencies: [],
  reportTexts: [],
  thetaPercentiles: [],
}

export const baseDataReducer = persistReducer(
  {
    storage,
    key: 'point-leader-base-data',
    whitelist: [
      'assessments',
      'scales',
      'competencies',
      'subscales',
      'questionScoreTypes',
      'questionWorkflowTypes',
      'clients',
      'jobs',
      'defaultJobCompetencyCuts',
      'clientSiteJobTopCompetencies',
      'reportTexts',
      'thetaPercentiles',
    ],
  },
  (state: IBaseDataState = initialBaseDataState, action: ActionWithPayload<IBaseDataState>) => {
    switch (action.type) {
      case baseDataActionTypes.LoadedDefaultJobCompetencyCuts: {
        const defaultJobCompetencyCuts = action.payload?.defaultJobCompetencyCuts || []
        return {...state, defaultJobCompetencyCuts}
      }
      case baseDataActionTypes.LoadedClientSiteJobTopCompetencies: {
        const clientSiteJobTopCompetencies = action.payload?.clientSiteJobTopCompetencies || []
        return {...state, clientSiteJobTopCompetencies}
      }
      case baseDataActionTypes.LoadedAssessments: {
        const assessments = action.payload?.assessments || []
        return {...state, assessments}
      }
      case baseDataActionTypes.LoadedParentScales: {
        const parentScales = action.payload?.scales || []
        return {
          ...state,
          scales: parentScales.filter((item) => item.type === parentScaleTypes.scale),
          competencies: parentScales.filter((item) => item.type === parentScaleTypes.competency),
        }
      }
      case baseDataActionTypes.LoadedSubscales: {
        const subscales = action.payload?.subscales || []
        return {...state, subscales}
      }
      case baseDataActionTypes.LoadedQuestionScores: {
        const questionScores = action.payload?.questionScores || []
        return {...state, questionScores}
      }
      case baseDataActionTypes.LoadedQuestionWorkflows: {
        const questionWorkflows = action.payload?.questionWorkflows || []
        return {...state, questionWorkflows}
      }
      case baseDataActionTypes.LoadedClients: {
        const clients = action.payload?.clients || []
        return {...state, clients}
      }
      case baseDataActionTypes.LoadedJobs: {
        const jobs = action.payload?.jobs || []
        return {...state, jobs}
      }
      case baseDataActionTypes.LoadedReportTexts: {
        const reportTexts = action.payload?.reportTexts || []
        return {...state, reportTexts}
      }
      case baseDataActionTypes.LoadedThetaPercentiles: {
        const thetaPercentiles = action.payload?.thetaPercentiles || []
        return {...state, thetaPercentiles}
      }
      default:
        return state
    }
  }
)

export const baseDataActions = {
  loadDefaultJobCompetencyCuts: () => ({type: baseDataActionTypes.LoadDefaultJobCompetencyCuts}),
  fulfillDefaultJobCompetencyCuts: (defaultJobCompetencyCuts: CompetencyCutModel[]) => {
    return {
      type: baseDataActionTypes.LoadedDefaultJobCompetencyCuts,
      payload: {defaultJobCompetencyCuts},
    }
  },
  loadClientSiteJobTopCompetencies: () => ({
    type: baseDataActionTypes.LoadClientSiteJobTopCompetencies,
  }),
  fulfillClientSiteJobTopCompetencies: (
    clientSiteJobTopCompetencies: ClientSiteJobTopCompetencyModel[]
  ) => {
    return {
      type: baseDataActionTypes.LoadedClientSiteJobTopCompetencies,
      payload: {clientSiteJobTopCompetencies},
    }
  },
  loadAssessments: () => ({type: baseDataActionTypes.LoadAssessments}),
  fulfillAssessments: (assessments: AssessmentModel[]) => {
    return {
      type: baseDataActionTypes.LoadedAssessments,
      payload: {assessments},
    }
  },
  loadParentScales: () => ({type: baseDataActionTypes.LoadParentScales}),
  fulfillParentScales: (parentScales: ParentScaleModel[]) => ({
    type: baseDataActionTypes.LoadedParentScales,
    payload: {scales: parentScales},
  }),
  loadSubscales: () => ({type: baseDataActionTypes.LoadSubscales}),
  fulfillSubscales: (subscales: SubscaleModel[]) => ({
    type: baseDataActionTypes.LoadedSubscales,
    payload: {subscales},
  }),
  loadQuestionScores: () => ({type: baseDataActionTypes.LoadQuestionScores}),
  fulfillQuestionScores: (questionScores: QuestionScoreModel[]) => ({
    type: baseDataActionTypes.LoadedQuestionScores,
    payload: {questionScores},
  }),
  loadQuestionWorkflows: () => ({type: baseDataActionTypes.LoadQuestionWorkflows}),
  fulfillQuestionWorkflows: (questionWorkflows: QuestionWorkflowModel[]) => ({
    type: baseDataActionTypes.LoadedQuestionWorkflows,
    payload: {questionWorkflows},
  }),
  loadClients: () => ({type: baseDataActionTypes.LoadClients}),
  fulfillClients: (clients: ClientModel[]) => ({
    type: baseDataActionTypes.LoadedClients,
    payload: {clients},
  }),
  loadJobs: () => ({type: baseDataActionTypes.LoadJobs}),
  fulfillJobs: (jobs: JobModel[]) => ({
    type: baseDataActionTypes.LoadedJobs,
    payload: {jobs},
  }),
  loadReportTexts: () => ({type: baseDataActionTypes.LoadReportTexts}),
  fulfillReportTexts: (reportTexts: JobModel[]) => ({
    type: baseDataActionTypes.LoadedReportTexts,
    payload: {reportTexts},
  }),
  loadThetaPercentiles: () => ({type: baseDataActionTypes.LoadThetaPercentiles}),
  fulfillThetaPercentiles: (thetaPercentiles: JobModel[]) => ({
    type: baseDataActionTypes.LoadedThetaPercentiles,
    payload: {thetaPercentiles},
  }),
}

export function* sagaBaseData() {
  yield takeLatest(
    baseDataActionTypes.LoadDefaultJobCompetencyCuts,
    function* loadDefaultJobCompetencyCuts() {
      const {data} = yield axios.get<any>(apiRouterItems.DEFAULT_COMPETENCY_CUTS)
      yield put(baseDataActions.fulfillDefaultJobCompetencyCuts(data))
    }
  )
  yield takeLatest(
    baseDataActionTypes.LoadClientSiteJobTopCompetencies,
    function* loadClientSiteJobTopCompetencies() {
      const {data} = yield axios.get<any>(apiRouterItems.CLIENT_SITE_TOP_JOB_COMPETENCIES)
      yield put(baseDataActions.fulfillClientSiteJobTopCompetencies(data))
    }
  )
  yield takeLatest(baseDataActionTypes.LoadAssessments, function* loadAssessments() {
    const {data} = yield axios.get<any>(apiRouterItems.ASSESSMENTS)
    yield put(baseDataActions.fulfillAssessments(data.data))
  })
  yield takeLatest(baseDataActionTypes.LoadParentScales, function* loadParentScales() {
    const {data: parentScales} = yield axios.get<ParentScaleModel[]>(apiRouterItems.PARENT_SCALES)
    yield put(baseDataActions.fulfillParentScales(parentScales))
  })
  yield takeLatest(baseDataActionTypes.LoadSubscales, function* loadSubscales() {
    const {data: subscales} = yield axios.get<SubscaleModel[]>(apiRouterItems.SUBSCALES)
    yield put(baseDataActions.fulfillSubscales(subscales))
  })
  yield takeLatest(baseDataActionTypes.LoadQuestionScores, function* loadQuestionScores() {
    const {data: questionScores} = yield axios.get<QuestionScoreModel[]>(
      apiRouterItems.QUESTION_SCORES
    )
    yield put(baseDataActions.fulfillQuestionScores(questionScores))
  })
  yield takeLatest(baseDataActionTypes.LoadQuestionWorkflows, function* loadQuestionWorkflows() {
    const {data: questionWorkflows} = yield axios.get<QuestionWorkflowModel[]>(
      apiRouterItems.QUESTION_WORKFLOWS
    )
    yield put(baseDataActions.fulfillQuestionWorkflows(questionWorkflows))
  })
  yield takeLatest(baseDataActionTypes.LoadClients, function* loadClients() {
    const {data: clients} = yield axios.get<ClientModel[]>(apiRouterItems.CLIENTS_ALL)
    yield put(baseDataActions.fulfillClients(clients))
  })
  yield takeLatest(baseDataActionTypes.LoadJobs, function* loadJobs() {
    const {data: jobs} = yield axios.get<JobModel[]>(apiRouterItems.JOBS_ALL)
    yield put(baseDataActions.fulfillJobs(jobs))
  })
  yield takeLatest(baseDataActionTypes.LoadReportTexts, function* loadReportTexts() {
    const {data: reportTexts} = yield axios.get<JobModel[]>(apiRouterItems.REPORT_TEXTS_ALL)
    yield put(baseDataActions.fulfillReportTexts(reportTexts))
  })
  yield takeLatest(baseDataActionTypes.LoadThetaPercentiles, function* loadThetaPercentiles() {
    const {data: thetaPercentiles} = yield axios.get<JobModel[]>(apiRouterItems.THETA_PERCENTILES)
    yield put(baseDataActions.fulfillThetaPercentiles(thetaPercentiles))
  })
}
