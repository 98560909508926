import {FC} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {UserModel} from '../../../../app/models/UserModel'
import {RootState} from '../../../../setup'
import {Languages} from './Languages'
import * as auth from '../../../../app/modules/auth/redux/AuthRedux'
import {useDispatch} from 'react-redux'
import routerItems from '../../../../app/routing/routerItems'
import stringHelper from '../../../../app/utils/stringHelper'
import permissionHelper from '../../../../app/utils/authHelper'
import {Dropdown} from 'react-bootstrap'
import axios from 'axios'
import apiRouterItems from '../../../../app/constants/apiRouterItems'

const HeaderUserMenu: FC = () => {
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel
  const roles = user.roles

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const logout = () => {
    dispatch(auth.userActions.logout())
    navigate(routerItems.HOME)
  }
  const handleSwitchToOriginalUser = async () => {
    try {
      const {data} = await axios.post(apiRouterItems.SUPER_ADMIN_SWITCH_TO_ORIGINAL_USER)
      dispatch(auth.userActions.login(data.accessToken))
      navigate(routerItems.HOME)
    } catch (err) {}
  }

  return (
    <div className='d-flex align-items-center ml-3'>
      <Dropdown>
        <Dropdown.Toggle
          as='div'
          className='cursor-pointer symbol symbol-30px symbol-md-40px none-caret'
        >
          <img src={stringHelper.getUserAvatar(user.pic)} alt={user.firstName} />
        </Dropdown.Toggle>

        <Dropdown.Menu align='end'>
          <Dropdown.Item className='menu-item px-3'>
            <div className='menu-content d-flex align-items-center px-3'>
              <div className='symbol symbol-50px me-5'>
                <img alt='Logo' src={stringHelper.getUserAvatar(user.pic)} />
              </div>

              <div className='d-flex flex-column'>
                <div className='fw-bolder d-flex align-items-center fs-5'>
                  {user.firstName} {user.lastName}
                  <span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
                    Pro
                  </span>
                </div>
                <a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
                  {user.email}
                </a>
              </div>
            </div>
          </Dropdown.Item>

          {user.anonymousUser && (
            <>
              <Dropdown.Divider />
              <Dropdown.Item
                className='menu-item menu-link px-3 py-2 fw-bold fs-5'
                onClick={() => handleSwitchToOriginalUser()}
              >
                Switch to{' '}
                <span className='text-primary'>
                  {stringHelper.getUserFullName(user.anonymousUser)}
                </span>
              </Dropdown.Item>
            </>
          )}
          <Dropdown.Divider />
          <Dropdown.Item
            as={Link}
            to={routerItems.ACCOUNT_OVERVIEW}
            className='menu-item menu-link px-3 py-2 fw-bold fs-5'
          >
            My Profile
          </Dropdown.Item>
          {permissionHelper.hasEmployeeRole(roles) && (
            <Dropdown.Item
              as={Link}
              to={routerItems.ACCOUNT_OVERVIEW}
              className='menu-item menu-link px-3 py-2 fw-bold fs-5'
            >
              My Assessments
            </Dropdown.Item>
          )}
          <Dropdown.Item as={'button'} className='menu-item menu-link px-3 py-2 fw-bold fs-5'>
            <Languages />
          </Dropdown.Item>

          <Dropdown.Divider />
          <Dropdown.Item
            as={'button'}
            className='menu-item menu-link px-3 py-2 fw-bold fs-5'
            onClick={logout}
          >
            Sign Out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}

export {HeaderUserMenu}
